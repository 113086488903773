import React from 'react';
import Hero from '../components/Hero';
import JobOpenings from '../components/Careers/JobOpenings';
import CompanyCulture from '../components/Careers/CompanyCulture';
import MeetTheTeam from '../components/Careers/MeetTheTeam';
import CallToAction from '../components/CallToAction';

const Careers = () => {
  window.scrollTo(0, 0);

  return (
    <div className="bg-gray-100">
      {/* Hero Section */}
      <div className="animate-fadeInUp">
        <Hero
          titleKey="careers.hero.title"
          descriptionKey="careers.hero.description"
        />
      </div>

      {/* Job Openings Section */}
      <div className="animate-fadeInUp delay-200">
        <JobOpenings />
      </div>

      {/* Company Culture Section */}
      <div className="animate-fadeInUp delay-400">
        <CompanyCulture />
      </div>

      {/* Meet the Team Section */}
      <div className="animate-fadeInUp delay-600">
        <MeetTheTeam />
      </div>

      {/* Call to Action Section */}
      <div className="animate-fadeInUp delay-800">
        <CallToAction
          titleKey="careers.callToAction.title"
          descriptionKey="careers.callToAction.description"
          buttonTextKey="careers.callToAction.buttonText"
          buttonLink="/contact"
        />
      </div>
    </div>
  );
};

export default Careers;
