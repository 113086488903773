import React from 'react';
import { useTranslation } from 'react-i18next'; // i18next kullanımı için import
import ContactForm from '../components/Contact/ContactForm';
import ContactInfo from '../components/Contact/ContactInfo';
import ContactLocation from '../components/Contact/ContactLocation';
import Hero from '../components/Hero';

const Contact = () => {
  const { t } = useTranslation(); // dil değişimini sağlamak için useTranslation hook'u
  window.scrollTo(0, 0);

  return (
    <div className="bg-gray-100">
      {/* Hero Section */}
      <div className="animate-fadeInUp">
        <Hero
          titleKey="contact.hero.title" 
          descriptionKey="contact.hero.description" 
        />
      </div>

      {/* Contact Form Section */}
      <section className="container mx-auto py-16 px-4">
        <div className="bg-white shadow-lg rounded-lg p-8 md:p-16 lg:w-2/3 mx-auto animate-fadeInUp delay-200">
          <h2 className="text-3xl font-bold text-center mb-10 text-gray-800">{t('contact.title')}</h2>
          <p className="text-center text-gray-600 mb-10">
            {t('contact.subtitle')}
          </p>

          {/* Contact Form */}
          <ContactForm />

          {/* Additional Info */}
          <ContactInfo />
        </div>
      </section>

      {/* Google Map or Location Section */}
      <div className="animate-fadeInUp delay-400">
        <ContactLocation />
      </div>
    </div>
  );
};

export default Contact;
