import React from 'react';
import { Link } from 'react-router-dom';
import { FaTwitter, FaLinkedin, FaFacebook, FaInstagram } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';

const FollowUs = () => {
  const { t } = useTranslation();

  return (
    <div>
      <h4 className="text-xl font-bold mb-4">{t('footer.followUs.title')}</h4>
      <div className="flex space-x-4">
        <Link to="https://x.com/CosmicCode2024" className="text-blue-400 hover:text-blue-500 transition">
          <FaTwitter size={24} />
        </Link>
        <Link to="#" className="text-blue-400 hover:text-blue-500 transition">
          <FaLinkedin size={24} />
        </Link>
        <Link to="#" className="text-blue-400 hover:text-blue-500 transition">
          <FaFacebook size={24} />
        </Link>
        <Link to="#" className="text-blue-400 hover:text-blue-500 transition">
          <FaInstagram size={24} />
        </Link>
      </div>
    </div>
  );
};

export default FollowUs;
