export default {
  about: {
    hero: {
      title: "About Cosmohole",
      description:
        "At Cosmohole, we are redefining the accessibility and utilization of space data through innovative solutions.",
    },
    overview: {
      heading: "Company Overview",
      paragraphs: [
        "Established with the mission to revolutionize the accessibility of space data, Cosmohole has rapidly emerged as a pivotal entity at the intersection of space technology and data science. Our platform equips researchers, agencies, and enthusiasts with cutting-edge tools for the comprehensive analysis, visualization, and collaborative exploration of astronomical data.",
        "Committed to fostering innovation in space science, Cosmohole bridges the divide between raw space data and actionable insights. We strive to empower organizations to fully harness the potential of space exploration through advanced data visualization, predictive analytics, and AI-driven methodologies that illuminate the vast datasets generated by space missions.",
        "With a steadfast commitment to excellence, integrity, and collaboration, Cosmohole is dedicated to pushing the boundaries of possibilities within the cosmos. Our strategic partnerships with leading space agencies and esteemed research institutions worldwide underscore our commitment to advancing the frontiers of space science and technology.",
      ],
    },
    callToAction: {
      title: "Join Us on Our Journey",
      description:
        "We invite you to be a part of the transformative space data revolution. Whether you are a researcher, a space agency, or an inquisitive explorer, Cosmohole welcomes collaboration in the pursuit of advancing space science and technology.",
      buttonText: "Contact Us",
    },
  },
  contact: {
    hero: {
      title: "Get in Touch with Us",
      description:
        "We're here to help you with any inquiries or issues. Drop us a message, and our team will get back to you as soon as possible.",
    },
    title: "Contact Us",
    subtitle:
      "Feel free to reach out using the form below, and we will respond within 1-2 business days.",
    email: {
      text: "Alternatively, you can reach us via email at",
      emailAddress: "support@cosmohole.com",
    },
    phone: {
      text: "Or call us at",
      phoneNumber: "+1 800 123 4567",
    },
    location: {
      heading: "Our Office Location",
      address: "1234 Street Name, City, State, 56789",
    },
    form: {
      fullNameLabel: "Full Name",
      fullNamePlaceholder: "Enter your full name",
      emailLabel: "Email Address",
      emailPlaceholder: "Enter your email address",
      subjectLabel: "Subject",
      subjectPlaceholder: "Enter the subject",
      messageLabel: "Message",
      messagePlaceholder: "Type your message here",
      sendButtonText: "Send Message",
      thankYouTitle: "Thank You!",
      thankYouMessage: "Your message has been sent successfully.",
      loadingButtonText: "Sending...",
      sendButtonText: "Send",
    },
  },
  footer: {
    companyInfo: {
      name: "Cosmohole",
      description: "Redefining the accessibility of space data.",
      founded: "Founded in 2024",
      email: "support@cosmohole.com",
    },
    quickLinks: {
      quickLinksTitle: "Quick Links",
      quickLinks: [
        { label: "Home", path: "/" },
        { label: "About", path: "/about" },
        { label: "Services", path: "/services" },
        { label: "Portfolio", path: "/portfolio" },
        { label: "Contact", path: "/contact" },
        { label: "Careers", path: "/careers" },
        { label: "Blog", path: "/blog" },
        { label: "Reviews", path: "/reviews" },
      ],
    },
    stayUpdated: {
      title: "Stay Updated",
      description: "Subscribe to our newsletter for the latest updates:",
      placeholder: "Your Email Address",
      buttonText: "Subscribe",
      successMessage: "Successfully subscribed!",
      errorMessage: "An unexpected error occurred.",
    },
    followUs: {
      title: "Follow Us",
    },
    copyright: "© 2024 COSMOHOLE. All rights reserved.",
  },

  navbar: {
    logo: "COSMOHOLE",
    mainLinks: [
      { name: "Home", path: "/" },
      { name: "About", path: "/about" },
      { name: "Services", path: "/services" },
      { name: "Portfolio", path: "/portfolio" },
      { name: "Contact", path: "/contact" },
    ],
    moreLinks: [
      { name: "Careers", path: "/careers" },
      { name: "Blog", path: "/blog" },
      { name: "Reviews", path: "/reviews" },
    ],

    languageOptions: [
      { label: "English", code: "en" },
      { label: "Türkçe", code: "tr" },
      { label: "日本語", code: "ja" },

      
    ],
    more: "More",
  },

  services: {
    hero: {
      title: "Our Services",
      description:
        "Discover our cutting-edge space data solutions designed for researchers, agencies, and innovators alike.",
    },
    heading: "What We Offer",
    callToAction: {
      title: "Ready to Collaborate?",
      description:
        "If you are interested in leveraging our services or collaborating with us, get in touch today to explore how Cosmohole can help you navigate the complexities of space data.",
      buttonText: "Contact Us",
    },
    cards: {
      spaceDataAnalysis: {
        title: "Space Data Analysis",
        description:
          "We provide advanced tools to analyze complex space data, transforming vast amounts of information into actionable insights.",
      },
      visualizationSolutions: {
        title: "Visualization Solutions",
        description:
          "Our visualization solutions help turn complex space data into interactive, meaningful visual representations for both public and academic users.",
      },
      researchDevelopment: {
        title: "Research & Development",
        description:
          "We push the boundaries of innovation with R&D projects, continuously working on new methodologies in space science and technology.",
      },
      innovationTechnology: {
        title: "Innovation and Technology",
        description:
          "Driving innovation in data processing technology, we create solutions that are at the cutting edge of space exploration and science.",
      },
      customSolutions: {
        title: "Custom Solutions",
        description:
          "We design custom software solutions tailored to meet the unique needs of research centers, space agencies, and academic institutions.",
      },
      communityCollaboration: {
        title: "Community Collaboration",
        description:
          "We actively engage with the public and academic communities to foster collaboration and make space data accessible to everyone.",
      },
    },
  },

  careers: {
    hero: {
      title: "Join Our Team",
      description:
        "At Cosmohole, we are shaping the future of space data analysis and visualization. We're always looking for talented individuals who are passionate about innovation and technology.",
    },
    jobOpenings: {
      title: "Current Openings",
      dataScientist: {
        title: "Data Scientist",
        description:
          "We're looking for a Data Scientist to analyze complex space data and provide actionable insights.",
      },
      softwareEngineer: {
        title: "Software Engineer",
        description:
          "Join our team as a Software Engineer to develop innovative software solutions for space data analysis.",
      },
      projectManager: {
        title: "Project Manager",
        description:
          "We are seeking a Project Manager to oversee projects and ensure timely delivery of solutions.",
      },
      viewDetails: "View Details",
    },
    companyCulture: {
      title: "Our Company Culture",
      description:
        "At Cosmohole, we foster a collaborative and inclusive work environment. Our team thrives on diverse perspectives, encouraging innovation and creativity in every project. We believe in continuous growth, providing opportunities for professional development and promoting a healthy work-life balance.",
    },
    meetTheTeam: {
      title: "Meet Our Team",
      teamMember1: {
        name: "Nazli Nur ESMERAY",
        role: "Founder & Computer Engineer",
        links: {
          instagram: "https://www.instagram.com/nazlinres23984/?hl=tr",
          github: "https://github.com/nazlinres01",
          linkedin:
            "https://www.linkedin.com/in/nazl%C4%B1-nur-esmeray-155a36213/",
        },
      },
      teamMember2: {
        name: "Medine KAYNAK",
        role: "General Manager & Computer Engineer",
        links: {
          instagram: "https://www.instagram.com/maybeno1729/?hl=tr",
          github: "https://github.com/medine1729",
          linkedin: "https://www.linkedin.com/in/medinekaynak2906/",
        },
      },
    },
  },
  default: {
    callToAction: {
      title: "Ready to Collaborate?",
      description:
        "If you are interested in leveraging our services or collaborating with us, get in touch today to explore how Cosmohole can help you navigate the complexities of space data.",
      buttonText: "Contact Us",
    },
  },
  portfolio: {
    hero: {
      title: "Our Portfolio",
      description:
        "Explore our latest projects and the impact we've made in the industry.",
    },
    gallery: {
      title: "Our Work",
    },
    testimonials: {
      title: "What Our Clients Say",
    },
    gallery: {
      title: "Our Work",
      project1: {
        title: "Space Data Analysis and Visualization Project",
        description:
          "This project aims to create a platform focused on analyzing and visualizing space data for individuals interested in space science. By providing information on topics such as space travel, life on Mars, and the solar system, it will serve as an essential resource for both scientists and the general public.",
      },
      project2: {
        title: "Project Two",
        description: "A brief description of Project Two.",
      },
      project3: {
        title: "Project Three",
        description: "A brief description of Project Three.",
      },
      viewDetails: "View Details",
    },
    testimonials: {
      title: "Client Testimonials",
      client1: {
        name: "Dr. Alice Johnson",
        feedback:
          "The project exceeded my expectations and was delivered ahead of schedule. The team demonstrated exceptional professionalism and expertise.",
      },
      client2: {
        name: "Professor Mark Smith",
        feedback:
          "A highly competent team with remarkable insights into space data analysis. Their attention to detail was impressive.",
      },
      client3: {
        name: "Ms. Sarah Connor",
        feedback:
          "Fantastic collaboration throughout the project lifecycle. Their innovative approach brought great value to our objectives.",
      },
    },
  },
  project1: {
    hero: {
      title: "Space Data Analysis and Visualization Platform",
      subtitle:
        "A comprehensive platform to analyze, visualize, and explore space data for scientific research and public understanding.",
    },
    overview: {
      title: "Project Overview",
      description:
        "The Space Data Analysis and Visualization Platform is designed to process vast datasets from international space agencies and research institutions. By delivering interactive data visualizations, it enables users to explore, understand, and interact with complex space data efficiently. The platform fosters scientific innovation, accelerates research, and bridges the gap between the scientific community and the public, making space data more accessible.",
      goal1:
        "Streamline access to large-scale space datasets for experts and enthusiasts.",
      goal2:
        "Promote public awareness of space science through visually engaging tools.",
      goal3:
        "Offer cutting-edge analytics tools to support scientific discovery.",
      goal4:
        "Enhance collaboration between global space agencies and researchers.",
    },
    dataScope: {
      title: "Data Scope and Key Topics",
      description:
        "Our platform covers a broad spectrum of space-related topics by processing data from a variety of global sources. Users can delve into subjects ranging from the behavior of stars to the potential for life on other planets.",
      topic1: {
        title: "Sun and Solar Activity",
        details:
          "Analyze solar winds, solar storms, and their effects on Earth's atmosphere. Understand the solar cycles and their long-term impact on space weather.",
      },
      topic2: {
        title: "Stars and Galaxies",
        details:
          "Explore the formation and life cycles of stars, the discovery of exoplanets, and the interaction between galaxies through detailed simulations.",
      },
      topic3: {
        title: "Planets and Satellites",
        details:
          "Investigate planetary systems, including Mars colonization efforts, Earth-like planets in distant solar systems, and the giant gas planets and their moons.",
      },
      topic4: {
        title: "Astrobiology and Space Life",
        details:
          "Delve into astrobiological research, including the search for life on Mars, Europa, and other celestial bodies that may harbor life.",
      },
      topic5: {
        title: "Space Exploration and Future Missions",
        details:
          "Track ongoing and future space exploration missions, focusing on human spaceflight to Mars and beyond, and the role of private companies in space tourism.",
      },
      topic6: {
        title: "Space Phenomena and Cosmic Events",
        details:
          "Study cosmic phenomena such as meteor showers, black holes, neutron stars, and cosmic rays, and their effects on space travel and technology.",
      },
    },
    researchStrategy: {
      title: "Research and Analysis Strategy",
      description:
        "Our research strategy emphasizes the integration of cutting-edge technologies to provide precise and comprehensive space data analysis. By leveraging machine learning, data visualization, and advanced modeling, we empower scientists and enthusiasts alike to derive meaningful insights from complex datasets.",
      strategy1:
        "Utilize AI and machine learning algorithms to predict solar activity and potential space hazards.",
      strategy2:
        "Implement 3D and interactive data visualizations for planets, stars, and galaxies.",
      strategy3:
        "Develop custom analytical tools for in-depth exploration of astrobiological data.",
      strategy4:
        "Ensure data security and accuracy with blockchain-based validation mechanisms.",
    },
    conclusion: {
      title: "Project Impact and Future Outlook",
      description:
        "The Space Data Analysis and Visualization Platform will revolutionize the way space data is accessed, analyzed, and understood. By making complex information accessible to both experts and the public, it supports future space missions, fosters scientific advancements, and enhances global collaboration. This project not only contributes to the scientific community but also helps raise awareness about space exploration and its importance for humanity's future.",
    },
  },
  home: {
    welcomeText: "Welcome",
    servicesTitle: "Our Services",
    discoverSkillsAndGoals:
      "Discover your skills and let us help you reach your goals! Elevate your business with our professional services.",
    exploreServices: "Explore Our Services",
    aboutTitle: "About Us",
    aboutDescription:
      "Discover who we are, our mission, values, and the passion that drives our company forward. Learn about the journey that has shaped us and our commitment to excellence.",
    careerTitle: "Careers",
    careerDescription:
      "Join a dynamic team where your skills are valued and your career can flourish. Discover opportunities for personal growth, professional development, and impactful work.",
    portfolioTitle: "Portfolio",
    portfolioDescription:
      "Explore the diverse range of projects we have successfully completed. From innovative solutions to creative designs, our portfolio reflects our expertise and dedication to quality.",
    servicesTitle: "Services",
    servicesDescription:
      "We offer a wide array of services tailored to meet your unique needs. Discover how we can help you achieve your goals with our specialized expertise and comprehensive solutions.",
    contactTitle: "Contact",
    contactDescription:
      "We would love to hear from you! Whether you have a question, need assistance, or are interested in our services, feel free to reach out to us.",
    learnMore: "Learn More",
  },
  
  reviews: {
    title: "Customer Reviews",
    addReview: "Add Your Review",
    updateReview: "Update Review",
    nameLabel: "Your Name:",
    commentLabel: "Your Review:",
    ratingLabel: "Rating:",
    successMessage: "Success! Review submitted successfully.",
    errorMessage: "Error! Please fill in all fields.",
    noReviews: "No reviews yet.",
    submit: "Submit Review",
    update: "Update Review",
    rating: "Rating:",
  },
};
