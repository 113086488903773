import React, { useState, useEffect, useRef } from 'react';
import { FaAngleDown } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const MoreDropdown = ({ moreLinks }) => {
  const { t } = useTranslation();
  const [isMoreOpen, setMoreOpen] = useState(false);
  const dropdownRef = useRef(null); // Dropdown için bir referans

  const toggleMore = () => setMoreOpen(!isMoreOpen);

  const handleLinkClick = () => {
    setMoreOpen(false); // Menüyü kapat
  };

  // Dışarı tıklama durumunu kapatmak için effect kullanıyoruz
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setMoreOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <li className="relative list-none" ref={dropdownRef}>
      <button
        className="flex items-center text-gray-500 hover:text-blue-600 font-semibold transition-all"
        onClick={toggleMore}
      >
        {t('navbar.more')} <FaAngleDown className={`ml-1 transform transition-transform duration-200 ${isMoreOpen ? 'rotate-180' : ''}`} />
      </button>
      {isMoreOpen && (
        <ul className="absolute left-0 mt-2 w-40 bg-white rounded-lg shadow-lg overflow-hidden z-10 list-none
          transform transition-all duration-300 ease-out opacity-100 scale-100">
          {moreLinks.map((link) => (
            <li key={link.name} className="list-none">
              <Link
                to={link.path}
                className="block px-4 py-2 text-gray-700 hover:bg-blue-500 hover:text-white transition-all duration-200"
                onClick={handleLinkClick} // Tıklanınca menüyü kapat
              >
                {link.name}
              </Link>
            </li>
          ))}
        </ul>
      )}
    </li>
  );
};

export default MoreDropdown;
