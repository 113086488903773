import React from 'react';
import { useTranslation } from 'react-i18next';

const ResearchStrategy = () => {
  const { t } = useTranslation();

  return (
    <section className="py-20 bg-gray-50">
      <div className="container mx-auto px-6 text-center">
        <h2 className="text-5xl font-bold mb-12">{t('project1.researchStrategy.title')}</h2>
        <p className="text-xl mb-12 max-w-4xl mx-auto text-gray-700 leading-relaxed">
          {t('project1.researchStrategy.description')}
        </p>
        <ul className="list-none space-y-8">
          {[...Array(4)].map((_, i) => (
            <li
              className="bg-white p-8 shadow-lg rounded-lg transition-all duration-300 transform hover:scale-105 hover:shadow-2xl"
              key={i}
            >
              <h3 className="text-xl font-semibold mb-4">{t(`project1.researchStrategy.strategy${i + 1}`)}</h3>
            </li>
          ))}
        </ul>
      </div>
    </section>
  );
};

export default ResearchStrategy;
