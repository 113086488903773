import React, { useState, useEffect } from 'react';
import { fetchReviews, addReview, updateReview, deleteReview } from '../api/reviews'; // API fonksiyonlarını içe aktar
import ReviewForm from '../components/ReviewForm';
import ReviewList from '../components/ReviewList';
import { useTranslation } from 'react-i18next'; // Import translation hook

const Reviews = () => {
  const { t } = useTranslation(); // Use translation hook
  const [reviews, setReviews] = useState([]);
  const [editReviewId, setEditReviewId] = useState(null); // Güncelleme için kullanılacak ID

  // Yorumları çekmek için useEffect
  useEffect(() => {
    const loadReviews = async () => {
      try {
        const fetchedReviews = await fetchReviews(); // API'den yorumları çek
        setReviews(fetchedReviews);
      } catch (error) {
        console.error(error.message);
      }
    };

    loadReviews();
  }, []);

  // Yorum ekleme veya güncelleme fonksiyonu
  const handleSubmit = async (newReview) => {
    try {
      if (editReviewId) {
        // Güncelleme yapılıyorsa
        await updateReview(editReviewId, newReview); // Yorum güncelle
        setEditReviewId(null); // Güncelleme işlemi tamamlandıktan sonra ID'yi sıfırla
      } else {
        // Yeni yorum ekleniyorsa
        await addReview(newReview); // Yorum ekle
      }

      // Yeni yorumları tekrar çek
      const fetchedReviews = await fetchReviews();
      setReviews(fetchedReviews);
    } catch (error) {
      console.error(error.message);
    }
  };

  // Yorum silme fonksiyonu
  const handleDelete = async (id) => {
    try {
      await deleteReview(id); // Yorum sil
      const fetchedReviews = await fetchReviews(); // Yeni yorumları tekrar çek
      setReviews(fetchedReviews);
    } catch (error) {
      console.error(error.message);
    }
  };

  // Güncelleme için formu doldurma fonksiyonu
  const handleEdit = (review) => {
    setEditReviewId(review._id); // Güncellenecek yorumun ID'sini ayarla
  };

  // Formu temizlemek için yardımcı fonksiyon
  const clearForm = () => {
    setEditReviewId(null);
  };

  const initialData = editReviewId ? reviews.find(review => review._id === editReviewId) : null;

  return (
    <div className="bg-gray-100 py-16">
      <div className="container mx-auto px-4">
        <h1 className="text-3xl md:text-4xl font-bold text-center mb-10 text-gray-800">
          {t('reviews.title')} {/* Use translation key for title */}
        </h1>

        {/* Yorum Ekleme veya Güncelleme Formu */}
        <ReviewForm 
          onSubmit={handleSubmit} 
          editReviewId={editReviewId} 
          initialData={initialData} 
          clearForm={clearForm} 
        />

        {/* Yorumların Listesi */}
        <ReviewList reviews={reviews} onEdit={handleEdit} onDelete={handleDelete} />
        
        {/* No reviews message */}
        {reviews.length === 0 && (
          <p className="text-gray-500 text-center font-bold text-lg">
            {t('reviews.noReviews')} {/* Use translation key for no reviews message */}
          </p>
        )}
      </div>
    </div>
  );
};

export default Reviews;
