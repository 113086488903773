import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { subscribeEmail } from '../../api/stayUpdated'; // API fonksiyonunu içe aktar

const StayUpdated = () => {
  const { t } = useTranslation();
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    setMessage('');
    setError('');

    try {
      const response = await subscribeEmail(email);
      setMessage(response.message || t('footer.stayUpdated.successMessage')); // Başarı mesajını göster
      setEmail(''); // Başarı durumunda formu temizle
    } catch (err) {
      setError(err.message || t('footer.stayUpdated.errorMessage')); // Hata mesajını ayarla
    }
  };

  return (
    <div>
      <h4 className="text-xl font-bold mb-4">{t('footer.stayUpdated.title')}</h4>
      <p className="text-gray-400 mb-2">{t('footer.stayUpdated.description')}</p>
      <form onSubmit={handleSubmit}>
        <input 
          type="email" 
          value={email} 
          onChange={(e) => setEmail(e.target.value)} 
          placeholder={t('footer.stayUpdated.placeholder')}
          className="p-2 rounded-md text-gray-800 w-full mb-2"
          required
        />
        <button 
          type="submit" 
          className="w-full bg-blue-600 text-white py-2 rounded-md hover:bg-blue-700 transition"
        >
          {t('footer.stayUpdated.buttonText')}
        </button>
      </form>
      {message && <p className="text-green-500 mt-2">{message}</p>}
      {error && <p className="text-red-500 mt-2">{error}</p>}
    </div>
  );
};

export default StayUpdated;
