import React from 'react';
import { useTranslation } from 'react-i18next';

const CompanyCulture = () => {
  const { t } = useTranslation();

  return (
    <section className="bg-white py-20">
      <div className="container mx-auto px-8 text-center">
        <h2 className="text-4xl font-bold text-gray-800 mb-6">
          {t('careers.companyCulture.title')}
        </h2>
        <p className="text-lg text-gray-700 mb-6 px-6 md:px-16 lg:px-32">
          {t('careers.companyCulture.description')}
        </p>
      </div>
    </section>
  );
};

export default CompanyCulture;
