// src/components/DataScope.js
import React from 'react';
import { useTranslation } from 'react-i18next';

const DataScope = () => {
  const { t } = useTranslation();

  return (
    <section className="py-20 bg-white">
      <div className="container mx-auto px-6 text-center">
        <h2 className="text-5xl font-bold mb-12">{t('project1.dataScope.title')}</h2>
        <p className="text-xl mb-12 max-w-4xl mx-auto text-gray-700 leading-relaxed">{t('project1.dataScope.description')}</p>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {[...Array(6)].map((_, i) => (
            <div className="bg-gray-100 p-8 shadow-lg rounded-lg" key={i}>
              <h3 className="text-xl font-semibold mb-4">{t(`project1.dataScope.topic${i + 1}.title`)}</h3>
              <p className="text-gray-600">{t(`project1.dataScope.topic${i + 1}.details`)}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default DataScope;
