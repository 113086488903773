import React from 'react';

const ServiceCard = ({ title, description, icon }) => {
  return (
    <div className="bg-white shadow-lg rounded-lg p-6">
      <div className="flex items-center justify-center bg-blue-500 text-white w-16 h-16 rounded-full mb-4">
        {icon}
      </div>
      <h3 className="font-bold text-xl mb-2">{title}</h3>
      <p className="text-gray-700">{description}</p>
    </div>
  );
}

export default ServiceCard;
