import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Helmet } from 'react-helmet'; // Helmet'i içe aktar
import Home from './pages/Home';
import About from './pages/About';
import Services from './pages/Services';
import Portfolio from './pages/Portfolio';
import Contact from './pages/Contact';
import Blog from './pages/Blog';
import Careers from './pages/Careers';
import Navbar from './components/Navbar/Navbar';
import Footer from './components/Footer/Footer';
import Project1 from './pages/Project1';
import Reviews from './pages/Reviews';
import './i18n';

const App = () => {
  return (
    <Router>
      <div className="flex flex-col min-h-screen">
        <Helmet>
          <title>COSMOHOLE</title> {/* Genel başlık ayarı */}
        </Helmet>
        <Navbar />
        <main className="flex-grow">
          <Routes>
            <Route path="/" element={<><Helmet><title>Home - COSMOHOLE</title></Helmet><Home /></>} />
            <Route path="/about" element={<><Helmet><title>About Us - COSMOHOLE</title></Helmet><About /></>} />
            <Route path="/services" element={<><Helmet><title>Our Services - COSMOHOLE</title></Helmet><Services /></>} />
            <Route path="/portfolio" element={<><Helmet><title>Our Portfolio - COSMOHOLE</title></Helmet><Portfolio /></>} />
            <Route path="/contact" element={<><Helmet><title>Contact Us - COSMOHOLE</title></Helmet><Contact /></>} />
            <Route path="/blog" element={<><Helmet><title>Blog - COSMOHOLE</title></Helmet><Blog /></>} />
            <Route path="/careers" element={<><Helmet><title>Careers - COSMOHOLE</title></Helmet><Careers /></>} />
            <Route path="/portfolio/project1" element={<><Helmet><title>Project 1 - COSMOHOLE</title></Helmet><Project1 /></>} />
            <Route path="/reviews/" element={<><Helmet><title>Reviews - COSMOHOLE</title></Helmet><Reviews /></>} />
          </Routes>
        </main>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
