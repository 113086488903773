import React, { useState, useEffect } from 'react';
import { FaStar } from 'react-icons/fa'; // Yıldız ikonları
import { useTranslation } from 'react-i18next'; // Importing translation hook

const ReviewForm = ({ onSubmit, editReviewId, initialData, clearForm }) => {
  const { t } = useTranslation(); // Use translation hook
  const [name, setName] = useState('');
  const [comment, setComment] = useState('');
  const [rating, setRating] = useState(5); // Varsayılan puan
  const [hoverRating, setHoverRating] = useState(0); // Hover için puan
  const [showSuccess, setShowSuccess] = useState(false); // Başarı mesajı için
  const [showError, setShowError] = useState(false); // Hata mesajı için
  const [errorMessage, setErrorMessage] = useState(''); // Hata mesajı metni

  useEffect(() => {
    if (initialData) {
      setName(initialData.customerName);
      setComment(initialData.review);
      setRating(initialData.rating);
    } else {
      clearForm();
    }
  }, [initialData, clearForm]);

  const handleSubmit = (e) => {
    e.preventDefault();
    setShowError(false);
    setShowSuccess(false);

    // Form doğrulaması: boş alan kontrolü
    if (!name.trim() || !comment.trim()) {
      setErrorMessage(t('reviews.errorMessage')); // Localized error message
      setShowError(true);
      return;
    }

    // Yorum gönderme işlemi
    onSubmit({ customerName: name, review: comment, rating });
    setShowSuccess(true); // Başarı mesajı göster

    // Formu temizle
    clearForm();
    setName('');
    setComment('');
    setRating(5);

    // Başarı mesajını 3 saniye sonra gizle
    setTimeout(() => setShowSuccess(false), 3000);
  };

  const renderStars = () => {
    const totalStars = 5;
    return [...Array(totalStars)].map((_, index) => {
      const starValue = index + 1;
      return (
        <FaStar
          key={index}
          size={24}
          className={`cursor-pointer transition-colors duration-300 ${
            starValue <= (hoverRating || rating) ? 'text-yellow-500' : 'text-gray-300'
          }`}
          onMouseEnter={() => setHoverRating(starValue)} // Yıldızlar üzerine gelince
          onMouseLeave={() => setHoverRating(0)} // Yıldızdan çıkınca
          onClick={() => setRating(starValue)} // Yıldız tıklaması
        />
      );
    });
  };

  return (
    <div className="w-full max-w-7xl mx-auto">
      <form
        onSubmit={handleSubmit}
        className="bg-white p-8 rounded-xl shadow-xl w-full mx-auto mt-10 space-y-6"
      >
        <h2 className="text-2xl font-bold text-gray-800 mb-4">
          {editReviewId ? t('reviews.updateReview') : t('reviews.addReview')}
        </h2>

        {/* Ad Girişi */}
        <div className="flex flex-col">
          <label htmlFor="name" className="text-gray-600 font-semibold mb-2">
            {t('reviews.nameLabel')}
          </label>
          <input
            type="text"
            id="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            className="px-4 py-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition duration-200"
            placeholder={t('reviews.nameLabel')}
            required
          />
        </div>

        {/* Yorum Girişi */}
        <div className="flex flex-col">
          <label htmlFor="comment" className="text-gray-600 font-semibold mb-2">
            {t('reviews.commentLabel')}
          </label>
          <textarea
            id="comment"
            value={comment}
            onChange={(e) => setComment(e.target.value)}
            className="px-4 py-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition duration-200"
            placeholder={t('reviews.commentLabel')}
            required
            rows="4"
          ></textarea>
        </div>

        {/* Yıldız Puanı */}
        <div className="flex flex-col">
          <label className="text-gray-600 font-semibold mb-2">{t('reviews.ratingLabel')}</label>
          <div className="flex space-x-1">{renderStars()}</div>
        </div>

        {/* Başarı Mesajı */}
        {showSuccess && (
          <div className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative">
            <strong className="font-bold">{t('reviews.successMessage')}</strong>
          </div>
        )}

        {/* Hata Mesajı */}
        {showError && (
          <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative">
            <strong className="font-bold">Hata!</strong>
            <span className="block sm:inline"> {errorMessage}</span>
          </div>
        )}

        {/* Gönder Butonu */}
        <button
          type="submit"
          className="w-full bg-gradient-to-r from-blue-500 to-blue-600 text-white font-semibold py-3 rounded-lg shadow-lg hover:from-blue-600 hover:to-blue-700 transition-transform duration-300 transform hover:scale-105"
        >
          {editReviewId ? t('reviews.update') : t('reviews.submit')}
        </button>
      </form>
    </div>
  );
};

export default ReviewForm;
