// src/Home.js
import React from "react";
import HomeCard from "../components/HomeCard";
import { useTranslation } from "react-i18next"; // i18next'ten çeviri kütüphanesini import ettik.

const Home = () => {
  const { t } = useTranslation(); // t fonksiyonunu kullanarak çeviri fonksiyonunu alıyoruz.

  return (
    <div className="p-0">
      {/* Hero Bölümü */}
      <div className="relative bg-blue-600 text-white py-60 mb-10 text-center overflow-hidden">
        {/* Video Arka Plan */}
        <video
          autoPlay
          loop
          muted
          className="absolute top-0 left-0 w-full h-full object-cover"
        >
          <source src="videos/welcome-video.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        {/* Üstteki İçerik */}
        <div className="relative z-10">
          <h2 className="text-8xl font-extrabold mb-4">
            {t("home.welcomeText")}
          </h2>
          <p className="text-2xl mb-4 max-w-xl mx-auto">
            {t("home.discoverSkillsAndGoals")}
          </p>
        </div>
      </div>

      {/* Ana İçerik */}
      <main>
        <div className="flex flex-col gap-6">
          <HomeCard
            title={t("home.aboutTitle")}
            description={t("home.aboutDescription")}
            link="/about" // Yönlendirme yolu
          />
          <HomeCard
            title={t("home.servicesTitle")}
            description={t("home.servicesDescription")}
            link="/services" // Yönlendirme yolu
          />
          <HomeCard
            title={t("home.portfolioTitle")}
            description={t("home.portfolioDescription")}
            link="/portfolio" // Yönlendirme yolu
          />
          <HomeCard
            title={t("home.contactTitle")}
            description={t("home.contactDescription")}
            link="/contact" // Yönlendirme yolu
          />
        </div>
      </main>
    </div>
  );
};

export default Home;
