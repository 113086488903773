import React from "react";
import { useTranslation } from "react-i18next";

const CompanyOverview = () => {
  const { t } = useTranslation();

  return (
    <section className="py-24 bg-gradient-to-b from-gray-50 to-white text-gray-800 relative">
      <div className="container mx-auto px-6 lg:px-8">
        <h2 className="text-4xl md:text-5xl font-extrabold text-center text-gray-900 mb-12 relative z-10">
          {t("about.overview.heading")}
        </h2>

        {/* Company Overview Content */}
        <div className="max-w-5xl mx-auto space-y-12 relative z-10">
          {t("about.overview.paragraphs", { returnObjects: true }).map(
            (paragraph, index) => (
              <p
                key={index}
                className="text-lg md:text-xl leading-relaxed mb-8 text-justify px-4 lg:px-10 tracking-wide"
              >
                {paragraph}
              </p>
            )
          )}
        </div>
      </div>
    </section>
  );
};

export default CompanyOverview;
