import React, { useState, useEffect, useRef } from 'react';
import { FaGlobe, FaAngleDown } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';

const LanguageDropdown = ({ languageOptions }) => {
  const { i18n } = useTranslation();
  const [isLanguageOpen, setLanguageOpen] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState(languageOptions[0]);
  const dropdownRef = useRef(null);

  const toggleLanguage = () => setLanguageOpen(!isLanguageOpen);

  const selectLanguage = (lang) => {
    setSelectedLanguage(lang);
    setLanguageOpen(false);
    i18n.changeLanguage(lang.code);
    console.log(`Language changed to: ${lang.label}`);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setLanguageOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="relative hidden md:block" ref={dropdownRef}>
      <button className="flex items-center text-gray-400 hover:text-blue-600 font-semibold transition-all" onClick={toggleLanguage}>
        <FaGlobe className="mr-1" /> {selectedLanguage.label} <FaAngleDown className={`ml-1 transform transition-transform duration-200 ${isLanguageOpen ? 'rotate-180' : ''}`} />
      </button>
      {isLanguageOpen && (
        <ul className="absolute right-0 mt-1 w-32 bg-white rounded-lg shadow-lg overflow-hidden z-10 list-none">
          {languageOptions.map((lang) => (
            <li key={lang.code} className="list-none">
              <button
                className="flex items-center px-2 py-1 text-gray-700 hover:bg-blue-500 hover:text-white transition-all duration-200 w-full text-left text-sm"
                onClick={() => selectLanguage(lang)}
              >
                {lang.label}
              </button>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default LanguageDropdown;
