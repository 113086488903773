import React from 'react';
import { useTranslation } from 'react-i18next';

const Hero = ({ titleKey, descriptionKey }) => {
  const { t } = useTranslation();

  return (
    <section className="relative bg-cover bg-center py-20">
      <img 
        src="/images/hero-background.jpg"
        alt="Kurumsal Arka Plan" 
        className="absolute inset-0 object-cover w-full h-full opacity-50" 
      />
      <div className="container mx-auto px-6 text-center relative z-10">
        <h1 className="text-4xl md:text-5xl font-bold text-gray-800 mb-4">
          {t(titleKey)}
        </h1>
        <p className="text-lg md:text-xl max-w-3xl mx-auto text-gray-700 font-bold">
          {t(descriptionKey)}
        </p>
      </div>
    </section>
  );
};

export default Hero;
