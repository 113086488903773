// src/pages/Portfolio.js
import React from "react";
import PortfolioGallery from "../components/Portfolio/PortfolioGallery";
import PortfolioTestimonials from "../components/Portfolio/PortfolioTestimonials";
import Hero from "../components/Hero";

const Portfolio = () => {
  window.scrollTo(0, 0);

  return (
    <div className="bg-gray-100">
      {/* Hero Section */}
      <div className="animate-fadeInUp">
        <Hero
          titleKey="portfolio.hero.title"
          descriptionKey="portfolio.hero.description"
        />
      </div>

      {/* Portfolio Gallery Section */}
      <div className="animate-fadeInUp delay-200">
        <PortfolioGallery />
      </div>

      {/* Portfolio Testimonials Section */}
      <div className="animate-fadeInUp delay-400">
        <PortfolioTestimonials />
      </div>
    </div>
  );
};

export default Portfolio;
