export default {
  about: {
    hero: {
      title: "Cosmohole Hakkında",
      description:
        "Cosmohole'da, uzay verilerine erişim ve kullanımını yenilikçi çözümlerle yeniden tanımlıyoruz.",
    },
    overview: {
      heading: "Şirket Genel Görünümü",
      paragraphs: [
        "Uzay verilerine erişimi devrim niteliğinde artırmak misyonuyla kurulan Cosmohole, uzay teknolojisi ve veri biliminin kesişiminde önemli bir oyuncu haline gelmiştir. Platformumuz, araştırmacılara, ajanslara ve meraklılara, astronomik verilerin kapsamlı analizi, görselleştirilmesi ve iş birliği ile keşfi için ileri düzey araçlar sunmaktadır.",
        "Uzay bilimlerinde yeniliği teşvik etmeye kararlıdır; Cosmohole, ham uzay verileri ile uygulanabilir içgörüler arasındaki boşluğu kapatmaktadır. Organizasyonların, uzay keşfinin potansiyelini tam anlamıyla kullanmalarını sağlamak için gelişmiş veri görselleştirmesi, tahmine dayalı analiz ve AI destekli yöntemler sunmaktadır.",
        "Mükemmellik, bütünlük ve iş birliğine olan bağlılığıyla Cosmohole, evrendeki olasılıkları zorlamaya devam etmektedir. Dünyaca ünlü uzay ajansları ve saygın araştırma kurumları ile olan stratejik ortaklıklarımız, uzay bilimi ve teknolojisinin sınırlarını ilerletme konusundaki kararlılığımızı göstermektedir.",
      ],
    },
    callToAction: {
      title: "Yolculuğumuza Katılın",
      description:
        "Uzay veri devriminin bir parçası olmaya davet ediyoruz. İster bir araştırmacı, ister bir uzay ajansı ya da meraklı bir kaşif olun, Cosmohole, uzay bilimleri ve teknolojisini ilerletmek için iş birliğine açıktır.",
      buttonText: "Bizimle İletişime Geçin",
    },
  },
  contact: {
    hero: {
      title: "Bize Ulaşın",
      description:
        "Herhangi bir soru veya sorunla ilgili size yardımcı olmak için buradayız. Mesajınızı bırakın, ekibimiz en kısa sürede size geri dönecektir.",
    },
    title: "Bizimle İletişime Geçin",
    subtitle:
      "Aşağıdaki formu kullanarak bizimle iletişime geçmekten çekinmeyin, 1-2 iş günü içinde size yanıt vereceğiz.",
    email: {
      text: "Alternatif olarak, bize e-posta ile ulaşabilirsiniz:",
      emailAddress: "support@cosmohole.com",
    },
    phone: {
      text: "Ya da bize şunlardan ulaşabilirsiniz:",
      phoneNumber: "+1 800 123 4567",
    },
    location: {
      heading: "Ofisimizin Konumu",
      address: "1234 Sokak Adı, Şehir, Eyalet, 56789",
    },
    form: {
      fullNameLabel: "Ad Soyad",
      fullNamePlaceholder: "Adınızı ve soyadınızı girin",
      emailLabel: "E-posta Adresi",
      emailPlaceholder: "E-posta adresinizi girin",
      subjectLabel: "Konu",
      subjectPlaceholder: "Konu başlığını girin",
      messageLabel: "Mesaj",
      messagePlaceholder: "Mesajınızı buraya yazın",
      sendButtonText: "Mesajı Gönder",
      thankYouTitle: "Teşekkürler!",
      thankYouMessage: "Mesajınız başarıyla gönderildi.",
      loadingButtonText: "Gönderiliyor...",
      sendButtonText: "Gönder",
    },
  },
  footer: {
    companyInfo: {
      name: "Cosmohole",
      description: "Uzay verilerinin erişilebilirliğini yeniden tanımlıyoruz.",
      founded: "2024'te kuruldu",
      email: "support@cosmohole.com",
    },
    quickLinks: {
      quickLinksTitle: "Hızlı Bağlantılar",
      quickLinks: [
        { label: "Ana Sayfa", path: "/" },
        { label: "Hakkımızda", path: "/about" },
        { label: "Hizmetler", path: "/services" },
        { label: "Portföy", path: "/portfolio" },
        { label: "İletişim", path: "/contact" },
        { label: "Kariyer", path: "/careers" },
        { label: "Blog", path: "/blog" },
        { label: "Müşteri Yorumları", path: "/reviews" },
      ],
    },
    stayUpdated: {
      title: "Güncel Kalın",
      description: "En son güncellemeler için bültenimize abone olun:",
      placeholder: "E-posta Adresiniz",
      buttonText: "Abone Ol",
      successMessage: "Başarıyla abone olundu!",
      errorMessage: "Beklenmeyen bir hata oluştu.",
    },
    followUs: {
      title: "Bizi Takip Edin",
    },
    copyright: "© 2024 COSMOHOLE. Tüm hakları saklıdır.",
  },

  navbar: {
    logo: "COSMOHOLE",
    mainLinks: [
      { name: "Anasayfa", path: "/" },
      { name: "Hakkımızda", path: "/about" },
      { name: "Hizmetler", path: "/services" },
      { name: "Portföy", path: "/portfolio" },
      { name: "İletişim", path: "/contact" },
    ],
    moreLinks: [
      { name: "Kariyer", path: "/careers" },
      { name: "Blog", path: "/blog" },
      { name: "Müşteri Yorumları", path: "/reviews" },
    ],

    languageOptions: [
      { label: "English", code: "en" },
      { label: "Türkçe", code: "tr" },
      { label: "日本語", code: "ja" },

    ],
    more: "Daha Fazla",
  },

  services: {
    hero: {
      title: "Hizmetlerimiz",
      description:
        "Araştırmacılar, ajanslar ve yenilikçiler için tasarlanmış en son uzay veri çözümlerimizi keşfedin.",
    },
    callToAction: {
      title: "İşbirliği Yapmaya Hazır mısınız?",
      description:
        "Hizmetlerimizden yararlanmak veya bizimle işbirliği yapmak istiyorsanız, Cosmohole'un uzay verilerinin karmaşıklığını nasıl yönetebileceğini keşfetmek için hemen iletişime geçin.",
      buttonText: "İletişime Geçin",
    },
    heading: "Neler Sunuyoruz",
    cards: {
      spaceDataAnalysis: {
        title: "Uzay Verisi Analizi",
        description:
          "Karmaşık uzay verilerini analiz etmek için gelişmiş araçlar sunuyoruz ve büyük miktarda bilgiyi uygulanabilir içgörülere dönüştürüyoruz.",
      },
      visualizationSolutions: {
        title: "Görselleştirme Çözümleri",
        description:
          "Görselleştirme çözümlerimiz, karmaşık uzay verilerini hem kamuya hem de akademik kullanıcılara yönelik interaktif ve anlamlı görsel temsillere dönüştürmeye yardımcı olur.",
      },
      researchDevelopment: {
        title: "Araştırma & Geliştirme",
        description:
          "Ar-Ge projeleri ile inovasyonun sınırlarını zorluyoruz ve uzay bilimleri ile teknolojisinde yeni metodolojiler üzerinde sürekli çalışıyoruz.",
      },
      innovationTechnology: {
        title: "İnovasyon ve Teknoloji",
        description:
          "Veri işleme teknolojisinde inovasyon sağlayarak, uzay keşfi ve biliminde en son çözümleri üretiyoruz.",
      },
      customSolutions: {
        title: "Özel Çözümler",
        description:
          "Araştırma merkezleri, uzay ajansları ve akademik kurumların ihtiyaçlarına özel yazılım çözümleri tasarlıyoruz.",
      },
      communityCollaboration: {
        title: "Topluluk İşbirliği",
        description:
          "Topluluk ve akademik işbirliğini teşvik ederek uzay verilerinin herkes tarafından erişilebilir olmasını sağlıyoruz.",
      },
    },
  },

  careers: {
    hero: {
      title: "Ekibimize Katılın",
      description:
        "Cosmohole'de, uzay veri analizinin ve görselleştirmenin geleceğini şekillendiriyoruz. Yenilik ve teknolojiye tutkuyla bağlı yetenekli bireyleri her zaman arıyoruz.",
    },
    jobOpenings: {
      title: "Mevcut Pozisyonlar",
      dataScientist: {
        title: "Veri Bilimcisi",
        description:
          "Karmaşık uzay verilerini analiz edip, uygulanabilir bilgiler sağlayacak bir Veri Bilimcisi arıyoruz.",
      },
      softwareEngineer: {
        title: "Yazılım Mühendisi",
        description:
          "Uzay veri analizi için yenilikçi yazılım çözümleri geliştirmek üzere ekibimize katılın.",
      },
      projectManager: {
        title: "Proje Yöneticisi",
        description:
          "Projeleri denetleyip, çözümlerin zamanında teslim edilmesini sağlayacak bir Proje Yöneticisi arıyoruz.",
      },
      viewDetails: "Detayları Gör",
    },
    companyCulture: {
      title: "Şirket Kültürümüz",
      description:
        "Cosmohole'da, iş birliğine dayalı ve kapsayıcı bir çalışma ortamını teşvik ediyoruz. Ekibimiz, her projede yenilik ve yaratıcılığı teşvik eden farklı bakış açılarıyla beslenmektedir. Sürekli büyümeye inanıyoruz; profesyonel gelişim fırsatları sunarak sağlıklı bir iş-yaşam dengesi sağlamaya çalışıyoruz.",
    },
    meetTheTeam: {
      title: "Ekibimizle Tanışın",
      teamMember1: {
        name: "Nazlı Nur ESMERAY",
        role: "Kurucu & Bilgisayar Mühendisi",
      },
      teamMember2: {
        name: "Medine KAYNAK",
        role: "Genel Müdür & Bilgisayar Mühendisi",
      },
    },
  },
  default: {
    callToAction: {
      title: "İş Birliği Yapmaya Hazır Mısınız?",
      description:
        "Hizmetlerimizden yararlanmak veya bizimle iş birliği yapmak istiyorsanız, Cosmohole'un uzay verilerinin karmaşıklıklarında size nasıl yardımcı olabileceğini keşfetmek için bugün bizimle iletişime geçin.",
      buttonText: "Bize Ulaşın",
    },
  },
  portfolio: {
    hero: {
      title: "Portföyümüz",
      description:
        "Son projelerimizi keşfedin ve sektörde yarattığımız etkiyi görün.",
    },
    gallery: {
      title: "Çalışmalarımız",
      project1: {
        title: "Uzay Veri Analizi ve Görselleştirme Projesi",
        description:
          "Bu proje, uzay verilerinin analiz edilmesi ve görselleştirilmesiyle uzay bilimine ilgi duyan bireylere yönelik bir platform oluşturmayı amaçlamaktadır. Uzay yolculuğu, Mars'ta yaşam ve güneş sistemi gibi konularda bilgi sunarak, hem bilim insanları hem de genel halk için önemli bir kaynak sağlayacaktır.",
      },
      project2: {
        title: "Proje İki",
        description: "Proje İki hakkında kısa bir açıklama.",
      },
      project3: {
        title: "Proje Üç",
        description: "Proje Üç hakkında kısa bir açıklama.",
      },
      viewDetails: "Detayları Gör",
    },
    testimonials: {
      title: "Müşteri Yorumları",
      client1: {
        name: "Dr. Alice Johnson",
        feedback:
          "Proje beklentilerimi aştı ve zamanında teslim edildi. Ekip olağanüstü profesyonellik ve uzmanlık gösterdi.",
      },
      client2: {
        name: "Profesör Mark Smith",
        feedback:
          "Uzay verileri analizi konusunda son derece yetkin bir ekip. Detaylara gösterdikleri özen etkileyiciydi.",
      },
      client3: {
        name: "Bayan Sarah Connor",
        feedback:
          "Proje süreci boyunca harika bir işbirliği. Yenilikçi yaklaşımları hedeflerimize büyük değer kattı.",
      },
    },
  },
  project1: {
    hero: {
      title: "Uzay Verileri Analiz ve Görselleştirme Platformu",
      subtitle:
        "Bilimsel araştırmalar ve kamu bilgilendirmesi için uzay verilerini analiz eden, görselleştiren ve keşfetmenize olanak sağlayan kapsamlı bir platform.",
    },
    overview: {
      title: "Proje Genel Bakış",
      description:
        "Uzay Verileri Analiz ve Görselleştirme Platformu, uluslararası uzay ajansları ve araştırma merkezlerinden elde edilen büyük ölçekli verileri işlemek üzere tasarlanmıştır. Platform, kullanıcıların bu karmaşık verileri etkili bir şekilde keşfetmelerine, anlamalarına ve etkileşimde bulunmalarına olanak tanıyan interaktif görselleştirmeler sunar. Bilimsel yenilikleri teşvik eder, araştırmaları hızlandırır ve bilim dünyası ile halk arasındaki boşluğu doldurarak uzay verilerini daha erişilebilir hale getirir.",
      goal1:
        "Uzmanlar ve meraklılar için büyük ölçekli uzay verilerine erişimi kolaylaştırmak.",
      goal2:
        "Görsel olarak etkileyici araçlar aracılığıyla uzay bilimi farkındalığını artırmak.",
      goal3:
        "Bilimsel keşifleri desteklemek için ileri analiz araçları sunmak.",
      goal4:
        "Küresel uzay ajansları ve araştırmacılar arasındaki işbirliğini geliştirmek.",
    },
    dataScope: {
      title: "Veri Kapsamı ve Ana Konular",
      description:
        "Platformumuz, geniş bir uzayla ilgili konu yelpazesini kapsayarak küresel kaynaklardan elde edilen verileri işler. Kullanıcılar, yıldızların davranışından diğer gezegenlerde yaşam olasılığına kadar çeşitli konuları inceleyebilirler.",
      topic1: {
        title: "Güneş ve Güneş Aktivitesi",
        details:
          "Güneş rüzgarları, güneş fırtınaları ve bunların Dünya'nın atmosferi üzerindeki etkilerini analiz edin. Güneş döngülerini ve uzay havası üzerindeki uzun vadeli etkilerini anlayın.",
      },
      topic2: {
        title: "Yıldızlar ve Galaksiler",
        details:
          "Yıldızların oluşumu ve yaşam döngüleri, ötegezegenlerin keşfi ve galaksiler arasındaki etkileşimleri detaylı simülasyonlar aracılığıyla keşfedin.",
      },
      topic3: {
        title: "Gezegenler ve Uydular",
        details:
          "Mars'ta kolonizasyon çalışmaları, uzak güneş sistemlerindeki Dünya'ya benzer gezegenler ve dev gaz gezegenler ile uydularını inceleyin.",
      },
      topic4: {
        title: "Astrobiyoloji ve Uzayda Yaşam",
        details:
          "Mars, Europa ve diğer gök cisimlerinde yaşam olasılıklarına yönelik astrobiyolojik araştırmalara dalın.",
      },
      topic5: {
        title: "Uzay Keşifleri ve Gelecek Misyonlar",
        details:
          "Mars ve ötesine yapılacak insanlı uzay uçuşlarını ve uzay turizmi alanındaki özel sektörün rolünü inceleyin.",
      },
      topic6: {
        title: "Uzay Olayları ve Kozmik Olaylar",
        details:
          "Meteor yağmurları, kara delikler, nötron yıldızları ve kozmik ışınlar gibi kozmik olayları ve bunların uzay yolculuğu ve teknoloji üzerindeki etkilerini inceleyin.",
      },
    },
    researchStrategy: {
      title: "Araştırma ve Analiz Stratejisi",
      description:
        "Araştırma stratejimiz, uzay verilerinin doğru ve kapsamlı analizini sağlamak için en son teknolojilerin entegrasyonuna odaklanmaktadır. Makine öğrenimi, veri görselleştirme ve gelişmiş modelleme yöntemlerini kullanarak, bilim insanlarına ve meraklılara karmaşık verilerden anlamlı çıkarımlar yapma fırsatı sunuyoruz.",
      strategy1:
        "Güneş aktivitelerini ve potansiyel uzay tehlikelerini tahmin etmek için yapay zeka ve makine öğrenimi algoritmalarını kullanmak.",
      strategy2:
        "Gezegenler, yıldızlar ve galaksiler için 3D ve etkileşimli veri görselleştirmelerini uygulamak.",
      strategy3:
        "Astrobiyolojik verilerin derinlemesine keşfi için özel analiz araçları geliştirmek.",
      strategy4:
        "Blockchain tabanlı doğrulama mekanizmalarıyla veri güvenliği ve doğruluğunu sağlamak.",
    },
    conclusion: {
      title: "Projenin Etkisi ve Gelecek Görünümü",
      description:
        "Uzay Verileri Analiz ve Görselleştirme Platformu, uzay verilerinin erişim, analiz ve anlama biçiminde devrim yaratacaktır. Hem uzmanlara hem de halka karmaşık bilgileri erişilebilir hale getirerek, gelecekteki uzay misyonlarını destekler, bilimsel gelişmeleri teşvik eder ve küresel işbirliğini geliştirir. Bu proje, sadece bilim dünyasına katkıda bulunmakla kalmayacak, aynı zamanda uzay keşfi ve bunun insanlığın geleceği için önemi konusunda farkındalık yaratacaktır.",
    },
  },

  home: {
    welcomeText: "Hoş Geldiniz",
    servicesTitle: "Hizmetlerimiz",
    discoverSkillsAndGoals:
      "Yeteneklerinizi keşfedin ve hedeflerinize ulaşmanıza yardımcı olun! Profesyonel hizmetlerimizle işinizi bir adım öteye taşıyın.",
    exploreServices: "Hizmetlerimizi Keşfedin",
    aboutTitle: "Hakkımızda",
    aboutDescription:
      "Kim olduğumuzu, misyonumuzu, değerlerimizi ve şirketimizi ileriye taşıyan tutkuyu keşfedin. Bizi şekillendiren yolculuğu ve mükemmeliyete olan bağlılığımızı öğrenin.",
    careerTitle: "Kariyer",
    careerDescription:
      "Becerilerinizin değer gördüğü ve kariyerinizin gelişebileceği dinamik bir ekibe katılın. Kişisel gelişim, profesyonel ilerleme ve etkili çalışmalar için fırsatları keşfedin.",
    portfolioTitle: "Portföy",
    portfolioDescription:
      "Başarıyla tamamladığımız geniş yelpazedeki projeleri keşfedin. Yenilikçi çözümlerden yaratıcı tasarımlara, portföyümüz uzmanlığımızı ve kaliteye olan bağlılığımızı yansıtır.",
    servicesTitle: "Hizmetler",
    servicesDescription:
      "Benzersiz ihtiyaçlarınıza uygun geniş bir hizmet yelpazesi sunuyoruz. Uzmanlığımız ve kapsamlı çözümlerimizle hedeflerinize ulaşmanız için size nasıl yardımcı olabileceğimizi keşfedin.",
    contactTitle: "İletişim",
    contactDescription:
      "Sizden haber almak bizi mutlu eder! Bir sorunuz varsa, yardıma ihtiyacınız olursa ya da hizmetlerimizle ilgileniyorsanız, bizimle iletişime geçmekten çekinmeyin.",
    learnMore: "Daha Fazla",
  },

  reviews: {
    title: "Müşteri Yorumları",
    addReview: "Yorumunuzu Ekleyin",
    updateReview: "Yorumu Güncelle",
    nameLabel: "Adınız:",
    commentLabel: "Yorumunuz:",
    ratingLabel: "Puan:",
    successMessage: "Başarılı! Yorum başarıyla gönderildi.",
    errorMessage: "Hata! Lütfen tüm alanları doldurun.",
    noReviews: "Henüz yorum yok.",
    submit: "Yorumu Gönder",
    update: "Yorumu Güncelle",
    rating: "Puan:",
  },
};
