import React from "react";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faInstagram,
  faGithub,
  faLinkedin,
} from "@fortawesome/free-brands-svg-icons";

const MeetTheTeam = () => {
  const { t } = useTranslation();

  const teamMembers = [
    {
      id: 1,
      name: t("careers.meetTheTeam.teamMember1.name"),
      role: t("careers.meetTheTeam.teamMember1.role"),
      image: "/images/team-member-1.jpg",
      links: {
        instagram: t("careers.meetTheTeam.teamMember1.links.instagram"),
        github: t("careers.meetTheTeam.teamMember1.links.github"),
        linkedin: t("careers.meetTheTeam.teamMember1.links.linkedin"),
      },
    },
    {
      id: 2,
      name: t("careers.meetTheTeam.teamMember2.name"),
      role: t("careers.meetTheTeam.teamMember2.role"),
      image: "/images/team-member-2.jpg",
      links: {
        instagram: t("careers.meetTheTeam.teamMember2.links.instagram"),
        github: t("careers.meetTheTeam.teamMember2.links.github"),
        linkedin: t("careers.meetTheTeam.teamMember2.links.linkedin"),
      },
    },
  ];

  return (
    <section className="container mx-auto py-20 px-4">
      <h2 className="text-5xl font-bold text-gray-800 mb-12 text-center">
        {t("careers.meetTheTeam.title")}
      </h2>
      <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-12">
        {teamMembers.map((member) => (
          <div
            key={member.id}
            className="bg-white rounded-lg shadow-lg p-6 text-center"
          >
            <img
              src={member.image}
              alt={member.name}
              className="w-32 h-32 rounded-full mx-auto mb-4"
            />
            <h3 className="text-2xl font-semibold mb-2">{member.name}</h3>
            <p className="text-gray-700">{member.role}</p>
            <div className="flex justify-center mt-4 space-x-4">
              <a
                href={member.links.linkedin}
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-500 hover:text-blue-700"
              >
                <FontAwesomeIcon icon={faLinkedin} size="lg" />
              </a>
              <a
                href={member.links.github}
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-500 hover:text-blue-700"
              >
                <FontAwesomeIcon icon={faGithub} size="lg" />
              </a>
              <a
                href={member.links.instagram}
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-500 hover:text-blue-700"
              >
                <FontAwesomeIcon icon={faInstagram} size="lg" />
              </a>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default MeetTheTeam;
