import React from 'react';
import { useTranslation } from 'react-i18next';

const ContactLocation = () => {
  const { t } = useTranslation();

  return (
    <section className="bg-gray-900 py-20">
      <div className="container mx-auto text-white text-center">
        <h3 className="text-2xl font-semibold mb-6">{t('contact.location.heading')}</h3>
        <p className="max-w-xl mx-auto mb-10">{t('contact.location.address')}</p>
        <div className="w-full h-64 bg-gray-600 rounded-lg">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3151.8354345093726!2d144.96305791531696!3d-37.81362797975159!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6ad65d43c9f873c9%3A0x2dfc20b31a5a1e0!2sFlinders%20St%2C%20Melbourne%20VIC%203000%2C%20Australia!5e0!3m2!1sen!2sus!4v1601572102123!5m2!1sen!2sus"
            title="Google Maps Location"
            width="100%"
            height="100%"
            frameBorder="0"
            allowFullScreen=""
            aria-hidden="false"
            tabIndex="0"
            className="rounded-lg"
          />
        </div>
      </div>
    </section>
  );
};

export default ContactLocation;
