import React from 'react';
import Hero from '../components/Hero';
import ProjectOverview from '../components/Project1/ProjectOverview';
import DataScope from '../components/Project1/DataScope';
import ResearchStrategy from '../components/Project1/ResearchStrategy';
import Conclusion from '../components/Project1/Conclusion';

const Project1 = () => {
  window.scrollTo(0, 0);

  return (
    <div>
      {/* Hero Section */}
      <Hero
        titleKey="project1.hero.title"
        descriptionKey="project1.hero.subtitle"
        className="animate-fadeInUp"
      />
      <div className="space-y-20">
        <div className="animate-fadeInUp">
          <ProjectOverview />
        </div>
        <div className="animate-fadeInUp delay-200">
          <DataScope />
        </div>
        <div className="animate-fadeInUp delay-400">
          <ResearchStrategy />
        </div>
        <div className="animate-fadeInUp delay-600">
          <Conclusion />
        </div>
      </div>
    </div>
  );
};

export default Project1;
