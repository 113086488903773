import React from 'react';
import { useTranslation } from 'react-i18next';

const ContactInfo = () => {
  const { t } = useTranslation();

  return (
    <div className="mt-12 text-center text-gray-600">
      <p>
        {t('contact.email.text')}{' '}
        <a href={`mailto:${t('contact.email.emailAddress')}`} className="text-blue-500 hover:underline">
          {t('contact.email.emailAddress')}
        </a>
      </p>
      <p className="mt-4">
        {t('contact.phone.text')} <span className="font-semibold">{t('contact.phone.phoneNumber')}</span>
      </p>
    </div>
  );
};

export default ContactInfo;
