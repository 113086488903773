import React from 'react';
import { FaSatelliteDish, FaRocket, FaChartLine, FaLightbulb, FaCogs, FaUsers } from 'react-icons/fa';
import ServiceCard from './ServiceCard';
import Hero from '../Hero'
import { useTranslation } from 'react-i18next';

const ServiceSection = () => {
  const { t } = useTranslation();

  return (
    <section>
      {/* Hizmet Kartları */}
      <section className="container mx-auto py-20 px-4">
        <h2 className="text-4xl font-bold text-center mb-12">{t('services.heading')}</h2>
        <div className="grid md:grid-cols-3 gap-12">
          <ServiceCard 
            title={t('services.cards.spaceDataAnalysis.title')} 
            description={t('services.cards.spaceDataAnalysis.description')}
            icon={<FaSatelliteDish />} 
          />
          <ServiceCard 
            title={t('services.cards.visualizationSolutions.title')} 
            description={t('services.cards.visualizationSolutions.description')}
            icon={<FaChartLine />} 
          />
          <ServiceCard 
            title={t('services.cards.researchDevelopment.title')} 
            description={t('services.cards.researchDevelopment.description')}
            icon={<FaRocket />} 
          />
          <ServiceCard 
            title={t('services.cards.innovationTechnology.title')} 
            description={t('services.cards.innovationTechnology.description')}
            icon={<FaLightbulb />} 
          />
          <ServiceCard 
            title={t('services.cards.customSolutions.title')} 
            description={t('services.cards.customSolutions.description')}
            icon={<FaCogs />} 
          />
          <ServiceCard 
            title={t('services.cards.communityCollaboration.title')} 
            description={t('services.cards.communityCollaboration.description')}
            icon={<FaUsers />} 
          />
        </div>
      </section>
    </section>
  );
};

export default ServiceSection;
