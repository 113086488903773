// src/HomeCard.js
import React from "react";
import { AiOutlineArrowRight } from "react-icons/ai"; // React Icons'dan ok ikonu
import { useTranslation } from "react-i18next"; // i18next'ten çeviri kütüphanesini import ettik.
import { Link } from "react-router-dom"; // Link bileşenini ekledik

const HomeCard = ({ title, description, link }) => { // link propunu ekledik
  const { t } = useTranslation(); // t fonksiyonunu kullanarak çeviri fonksiyonunu alıyoruz.

  return (
    <div className="bg-white shadow-lg rounded-lg p-8 md:p-12 transition-transform transform hover:scale-105 w-full mx-auto max-w-7xl border border-gray-300 relative overflow-hidden min-h-[250px]">
      <div className="absolute inset-0 bg-gradient-to-r from-gray-300 to-gray-500 opacity-20 rounded-lg"></div>
      <div className="relative z-10">
        <h3 className="text-2xl font-semibold mb-4 text-gray-800 hover:text-gray-600 transition-colors">
          {t(title)}
        </h3>
        <p className="text-gray-600 mb-6">{t(description)}</p>
        <Link to={link}> {/* Link bileşeni ile yönlendirme ekledik */}
          <button className="bg-gray-800 text-white px-4 py-2 rounded-md flex items-center justify-center hover:bg-gray-700 transition-colors duration-200">
            {t("home.learnMore")}
            <AiOutlineArrowRight className="ml-2" />
          </button>
        </Link>
      </div>
    </div>
  );
};

export default HomeCard;
