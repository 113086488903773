export default {
  about: {
    hero: {
      title: "コスモホールについて",
      description:
        "コスモホールでは、革新的なソリューションを通じて、宇宙データのアクセシビリティと活用法を再定義しています。",
    },
    overview: {
      heading: "会社概要",
      paragraphs: [
        "宇宙データのアクセシビリティを革命的に改善することを使命として設立されたコスモホールは、宇宙技術とデータサイエンスの交差点で重要な存在として急速に台頭しています。当社のプラットフォームは、研究者、機関、愛好者に対して、天文学データの包括的な分析、視覚化、共同探索のための最先端ツールを提供します。",
        "宇宙科学における革新を促進することにコミットしたコスモホールは、生の宇宙データと実用的な洞察のギャップを埋めています。私たちは、宇宙探査の可能性を完全に引き出すために、高度なデータ視覚化、予測分析、およびAI駆動の手法を通じて、宇宙ミッションによって生成される膨大なデータセットを照らすことを目指しています。",
        "卓越性、誠実性、協力への揺るぎないコミットメントを持って、コスモホールは宇宙内での可能性の限界を押し広げることに尽力しています。世界中の主要な宇宙機関や名高い研究機関との戦略的パートナーシップは、宇宙科学と技術の最前線を推進するという私たちのコミットメントを強調しています。",
      ],
    },
    callToAction: {
      title: "私たちの旅に参加しませんか",
      description:
        "変革的な宇宙データ革命の一部になりませんか。研究者、宇宙機関、または好奇心旺盛な探求者の方々、コスモホールは宇宙科学と技術を進めるための協力を歓迎します。",
      buttonText: "お問い合わせ",
    },
  },
  contact: {
    hero: {
      title: "お問い合わせ",
      description:
        "ご質問や問題があれば、私たちがサポートいたします。メッセージを送っていただければ、できるだけ早くお返事いたします。",
    },
    title: "お問い合わせ",
    subtitle:
      "以下のフォームを使用してお気軽にご連絡ください。1〜2営業日以内にお返事いたします。",
    email: {
      text: "また、以下のメールアドレスでご連絡いただくこともできます。",
      emailAddress: "support@cosmohole.com",
    },
    phone: {
      text: "または、以下の電話番号にお電話ください。",
      phoneNumber: "+1 800 123 4567",
    },
    location: {
      heading: "オフィス所在地",
      address: "1234 ストリート名, 市, 州, 56789",
    },
    form: {
      fullNameLabel: "フルネーム",
      fullNamePlaceholder: "フルネームを入力してください",
      emailLabel: "メールアドレス",
      emailPlaceholder: "メールアドレスを入力してください",
      subjectLabel: "件名",
      subjectPlaceholder: "件名を入力してください",
      messageLabel: "メッセージ",
      messagePlaceholder: "ここにメッセージを入力してください",
      sendButtonText: "メッセージを送信",
      thankYouTitle: "ありがとうございます！",
      thankYouMessage: "メッセージが正常に送信されました。",
      loadingButtonText: "送信中...",
      sendButtonText: "送信",
    },
  },

  footer: {
    companyInfo: {
      name: "コスモホール",
      description: "宇宙データのアクセシビリティを再定義しています。",
      founded: "2024年設立",
      email: "support@cosmohole.com",
    },
    quickLinks: {
      quickLinksTitle: "クイックリンク",
      quickLinks: [
        { label: "ホーム", path: "/" },
        { label: "会社概要", path: "/about" },
        { label: "サービス", path: "/services" },
        { label: "ポートフォリオ", path: "/portfolio" },
        { label: "お問い合わせ", path: "/contact" },
        { label: "キャリア", path: "/careers" },
        { label: "ブログ", path: "/blog" },
        { label: "推薦の声", path: "//reviews" },
      ],
    },
    stayUpdated: {
      title: "最新情報を受け取る",
      description: "最新情報を受け取るためにニュースレターに登録してください：",
      placeholder: "メールアドレス",
      buttonText: "登録",
      successMessage: "登録に成功しました！",
      errorMessage: "予期しないエラーが発生しました。",
    },
    followUs: {
      title: "フォローしてください",
    },
    copyright: "© 2024 COSMOHOLE. 全著作権所有。",
  },
  navbar: {
    logo: "COSMOHOLE",
    mainLinks: [
      { name: "ホーム", path: "/" },
      { name: "会社概要", path: "/about" },
      { name: "サービス", path: "/services" },
      { name: "ポートフォリオ", path: "/portfolio" },
      { name: "お問い合わせ", path: "/contact" },
    ],
    moreLinks: [
      { name: "キャリア", path: "/careers" },
      { name: "ブログ", path: "/blog" },
      { name: "推薦の声", path: "/reviews" },
    ],
    languageOptions: [
      { label: "English", code: "en" },
      { label: "Türkçe", code: "tr" },
      { label: "日本語", code: "ja" },
    ],
    more: "もっと見る",
  },
  services: {
    hero: {
      title: "私たちのサービス",
      description:
        "研究者、機関、革新者向けに設計された最先端の宇宙データソリューションを発見してください。",
    },
    heading: "私たちの提供内容",
    callToAction: {
      title: "コラボレーションの準備はできましたか？",
      description:
        "当社のサービスを活用することに興味がある場合、またはコラボレーションを希望される場合は、ぜひご連絡ください。コスモホールが宇宙データの複雑さをナビゲートするお手伝いをいたします。",
      buttonText: "お問い合わせ",
    },
    cards: {
      spaceDataAnalysis: {
        title: "宇宙データ分析",
        description:
          "複雑な宇宙データを分析するための高度なツールを提供し、大量の情報を実用的な洞察に変換します。",
      },
      visualizationSolutions: {
        title: "視覚化ソリューション",
        description:
          "当社の視覚化ソリューションは、複雑な宇宙データをインタラクティブで意味のある視覚表現に変換します。",
      },
      researchDevelopment: {
        title: "研究開発",
        description:
          "宇宙科学と技術における新しい手法を継続的に模索し、革新を推進するプロジェクトに取り組んでいます。",
      },
      innovationTechnology: {
        title: "革新と技術",
        description:
          "データ処理技術の革新を推進し、宇宙探査と科学の最前線であるソリューションを創造します。",
      },
      customSolutions: {
        title: "カスタムソリューション",
        description:
          "研究センター、宇宙機関、学術機関の独自のニーズに合わせたカスタムソフトウェアソリューションを設計します。",
      },
      communityCollaboration: {
        title: "コミュニティコラボレーション",
        description:
          "公衆および学術コミュニティとの連携を積極的に行い、宇宙データを誰でもアクセスできるようにします。",
      },
    },
  },

  careers: {
    hero: {
      title: "私たちのチームに参加しよう",
      description:
        "Cosmoholeでは、宇宙データ分析と視覚化の未来を形作っています。私たちは、革新と技術に情熱を持つ才能ある個人を常に求めています。",
    },
    jobOpenings: {
      title: "現在の求人",
      dataScientist: {
        title: "データサイエンティスト",
        description:
          "複雑な宇宙データを分析し、実用的な洞察を提供するデータサイエンティストを探しています。",
      },
      softwareEngineer: {
        title: "ソフトウェアエンジニア",
        description:
          "宇宙データ分析のための革新的なソフトウェアソリューションを開発するソフトウェアエンジニアとして私たちのチームに参加してください。",
      },
      projectManager: {
        title: "プロジェクトマネージャー",
        description:
          "プロジェクトを監督し、ソリューションの納期を確保するプロジェクトマネージャーを募集中です。",
      },
      viewDetails: "詳細を見る",
    },
    companyCulture: {
      title: "私たちの企業文化",
      description:
        "Cosmoholeでは、協力的で包括的な作業環境を育んでいます。私たちのチームは多様な視点を重視し、各プロジェクトでの革新と創造性を促進しています。私たちは継続的な成長を信じており、専門的な成長の機会を提供し、健康的なワークライフバランスを促進しています。",
    },
    meetTheTeam: {
      title: "私たちのチームを紹介します",
      teamMember1: {
        name: "ナズリ・ヌール・エスマレイ",
        role: "創設者 & コンピュータエンジニア",
        links: {
          instagram: "https://www.instagram.com/nazlinres23984/?hl=tr",
          github: "https://github.com/nazlinres01",
          linkedin:
            "https://www.linkedin.com/in/nazl%C4%B1-nur-esmeray-155a36213/",
        },
      },
      teamMember2: {
        name: "メディン・カイナク",
        role: "ゼネラルマネージャー & コンピュータエンジニア",
        links: {
          instagram: "https://www.instagram.com/maybeno1729/?hl=tr",
          github: "https://github.com/medine1729",
          linkedin: "https://www.linkedin.com/in/medinekaynak2906/",
        },
      },
    },
  },
  default: {
    callToAction: {
      title: "コラボレーションの準備はできましたか？",
      description:
        "私たちのサービスを活用したり、コラボレーションに興味がある場合は、ぜひご連絡ください。Cosmoholeが宇宙データの複雑さをナビゲートするお手伝いをします。",
      buttonText: "お問い合わせ",
    },
  },
  portfolio: {
    hero: {
      title: "私たちのポートフォリオ",
      description: "最新のプロジェクトと業界への影響を探ってみてください。",
    },
    gallery: {
      title: "私たちの仕事",
    },
    testimonials: {
      title: "クライアントの声",
    },
    gallery: {
      title: "私たちの仕事",
      project1: {
        title: "宇宙データ分析と視覚化プロジェクト",
        description:
          "このプロジェクトは、宇宙科学に興味のある人々のために宇宙データを分析し視覚化することに焦点を当てたプラットフォームを作成することを目指しています。宇宙旅行、火星での生活、太陽系などのトピックに関する情報を提供することで、科学者と一般市民の両方にとって重要なリソースとなることを目指します。",
      },
      project2: {
        title: "プロジェクト二",
        description: "プロジェクト二の簡潔な説明。",
      },
      project3: {
        title: "プロジェクト三",
        description: "プロジェクト三の簡潔な説明。",
      },
      viewDetails: "詳細を見る",
    },
    testimonials: {
      title: "クライアントの証言",
      client1: {
        name: "アリス・ジョンソン博士",
        feedback:
          "このプロジェクトは私の期待を超え、予定よりも早く納品されました。チームは卓越したプロフェッショナリズムと専門知識を示しました。",
      },
      client2: {
        name: "マーク・スミス教授",
        feedback:
          "宇宙データ分析に関する素晴らしい洞察を持つ非常に有能なチームです。彼らの細部への注意は印象的でした。",
      },
      client3: {
        name: "サラ・コナーさん",
        feedback:
          "プロジェクトのライフサイクル全体で素晴らしいコラボレーションがありました。彼らの革新的なアプローチは、私たちの目標に大きな価値をもたらしました。",
      },
    },
  },
  project1: {
    hero: {
      title: "宇宙データ分析と視覚化プラットフォーム",
      subtitle:
        "科学研究と一般理解のための宇宙データを分析、視覚化、探査する包括的なプラットフォーム。",
    },
    overview: {
      title: "プロジェクト概要",
      description:
        "宇宙データ分析と視覚化プラットフォームは、国際宇宙機関や研究機関からの膨大なデータセットを処理するために設計されています。インタラクティブなデータ視覚化を提供することで、ユーザーは複雑な宇宙データを効率的に探索し、理解し、対話することができます。このプラットフォームは科学的革新を促進し、研究を加速し、科学コミュニティと一般市民の間のギャップを埋め、宇宙データをよりアクセスしやすくします。",
      goal1:
        "専門家や愛好者向けに大規模な宇宙データセットへのアクセスを簡素化します。",
      goal2:
        "視覚的に魅力的なツールを通じて宇宙科学に対する一般の認識を促進します。",
      goal3: "科学的発見を支援する最先端の分析ツールを提供します。",
      goal4: "世界中の宇宙機関と研究者の間の協力を強化します。",
    },
    dataScope: {
      title: "データの範囲と主要トピック",
      description:
        "私たちのプラットフォームは、さまざまなグローバルソースからのデータを処理することにより、宇宙関連の広範なトピックをカバーしています。ユーザーは、星の挙動から他の惑星での生命の可能性まで、さまざまなテーマに深く掘り下げることができます。",
      topic1: {
        title: "太陽と太陽活動",
        details:
          "太陽風、太陽嵐、およびそれらが地球の大気に与える影響を分析します。太陽周期とそれらの宇宙天気への長期的影響を理解します。",
      },
      topic2: {
        title: "星と銀河",
        details:
          "星の形成とライフサイクル、系外惑星の発見、および詳細なシミュレーションを通じた銀河の相互作用を探索します。",
      },
      topic3: {
        title: "惑星と衛星",
        details:
          "火星の植民地化の試み、遠い太陽系の地球型惑星、および巨大ガス惑星とその衛星を含む惑星系を調査します。",
      },
      topic4: {
        title: "アストロバイオロジーと宇宙生命",
        details:
          "火星、エウロパ、および生命を宿す可能性のある他の天体の生命探査を含むアストロバイオロジーの研究を掘り下げます。",
      },
      topic5: {
        title: "宇宙探査と将来のミッション",
        details:
          "人間の火星への宇宙探査ミッションや、宇宙観光における民間企業の役割に焦点を当て、現在および将来の宇宙探査ミッションを追跡します。",
      },
      topic6: {
        title: "宇宙現象と宇宙イベント",
        details:
          "流星群、ブラックホール、中性子星、宇宙線などの宇宙現象を研究し、宇宙旅行や技術への影響を考察します。",
      },
    },
    researchStrategy: {
      title: "研究と分析戦略",
      description:
        "私たちの研究戦略は、正確で包括的な宇宙データ分析を提供するために、最先端の技術の統合を重視しています。機械学習、データ視覚化、および高度なモデリングを活用することで、科学者や愛好者が複雑なデータセットから意味のある洞察を導き出せるようにしています。",
      strategy1:
        "AIおよび機械学習アルゴリズムを活用して、太陽活動と潜在的な宇宙の危険を予測します。",
      strategy2:
        "惑星、星、銀河のための3Dおよびインタラクティブなデータ視覚化を実装します。",
      strategy3:
        "アストロバイオロジーデータの深層探索のためのカスタム分析ツールを開発します。",
      strategy4:
        "ブロックチェーンベースの検証メカニズムでデータのセキュリティと正確性を確保します。",
    },
    conclusion: {
      title: "プロジェクトの影響と将来の展望",
      description:
        "宇宙データ分析と視覚化プラットフォームは、宇宙データへのアクセス、分析、理解の方法を革命的に変えます。複雑な情報を専門家と一般市民の両方にアクセスしやすくすることで、将来の宇宙ミッションを支援し、科学の進歩を促進し、グローバルな協力を強化します。このプロジェクトは科学コミュニティに貢献するだけでなく、宇宙探査と人類の未来にとってその重要性についての認識を高めるのにも役立ちます。",
    },
  },
  home: {
    welcomeText: "ようこそ",
    servicesTitle: "私たちのサービス",
    discoverSkillsAndGoals:
      "あなたのスキルを発見し、私たちが目標達成をお手伝いします！私たちのプロフェッショナルサービスでビジネスを高めましょう。",
    exploreServices: "私たちのサービスを探る",
    aboutTitle: "私たちについて",
    aboutDescription:
      "私たちが誰であるか、私たちの使命、価値観、そして私たちの会社を前進させる情熱を発見してください。私たちを形作った旅と卓越性へのコミットメントについて学びましょう。",
    careerTitle: "キャリア",
    careerDescription:
      "あなたのスキルが評価され、キャリアが成長できるダイナミックなチームに参加しましょう。個人的な成長、専門的な開発、そして影響力のある仕事の機会を発見してください。",
    portfolioTitle: "ポートフォリオ",
    portfolioDescription:
      "私たちが成功裏に完了した多様なプロジェクトを探索してください。革新的なソリューションからクリエイティブなデザインまで、私たちのポートフォリオは専門知識と品質への献身を反映しています。",
    servicesTitle: "サービス",
    servicesDescription:
      "私たちは、あなたのユニークなニーズに応じた幅広いサービスを提供しています。私たちの専門知識と包括的なソリューションで目標を達成する方法を見つけてください。",
    contactTitle: "お問い合わせ",
    contactDescription:
      "あなたからのお便りをお待ちしています！質問がある場合、サポートが必要な場合、または私たちのサービスに興味がある場合は、お気軽にご連絡ください。",
    learnMore: "もっと知る",
  },
  reviews: {
    title: "顧客レビュー",
    addReview: "レビューを追加",
    updateReview: "レビューを更新",
    nameLabel: "あなたの名前:",
    commentLabel: "あなたのレビュー:",
    ratingLabel: "評価:",
    successMessage: "成功！レビューが正常に送信されました。",
    errorMessage: "エラー！すべてのフィールドを入力してください。",
    noReviews: "まだレビューがありません。",
    submit: "レビューを送信",
    update: "レビューを更新",
    rating: "評価:",
  },
};
 
