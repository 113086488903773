import React from "react";
import ReviewItem from "./ReviewItem";

const ReviewList = ({ reviews }) => {
  return (
    <div
      className="w-full max-w-screen-lg ml-4 overflow-y-auto mt-20 border-t border-gray-300 pt-5"
      style={{ maxHeight: "550px" }}
    >
      {reviews.length === 0 ? (
        <p className="text-gray-500 text-center font-bold text-lg">
        </p>
      ) : (
        reviews.slice().reverse().map((review) => (
          <ReviewItem key={review._id} review={review} />
        ))
      )}
    </div>
  );
};

export default ReviewList;
