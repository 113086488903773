// src/components/ProjectOverview.js
import React from 'react';
import { useTranslation } from 'react-i18next';

const ProjectOverview = () => {
  const { t } = useTranslation();

  return (
    <section id="overview" className="py-20 bg-gray-100">
      <div className="container mx-auto px-6 text-center">
        <h2 className="text-5xl font-bold mb-12 text-gray-800">{t('project1.overview.title')}</h2>
        <p className="text-xl mb-12 max-w-4xl mx-auto text-gray-700 leading-relaxed">{t('project1.overview.description')}</p>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          <div className="bg-white shadow-lg p-8 rounded-lg">
            <h3 className="text-xl font-semibold mb-4">{t('project1.overview.goal1')}</h3>
            <p className="text-gray-600">{t('project1.overview.goal1')}</p>
          </div>
          <div className="bg-white shadow-lg p-8 rounded-lg">
            <h3 className="text-xl font-semibold mb-4">{t('project1.overview.goal2')}</h3>
            <p className="text-gray-600">{t('project1.overview.goal2')}</p>
          </div>
          <div className="bg-white shadow-lg p-8 rounded-lg">
            <h3 className="text-xl font-semibold mb-4">{t('project1.overview.goal3')}</h3>
            <p className="text-gray-600">{t('project1.overview.goal3')}</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ProjectOverview;
