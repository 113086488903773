import axios from 'axios';

export const subscribeEmail = async (email) => {
  try {
    // Backend URL'yi tam olarak belirt
    const response = await axios.post('http://localhost:5000/api/stay-updated', { email });
    return response.data;
  } catch (error) {
    // Hata mesajı iletilerini güncelle
    throw new Error(error.response?.data?.message || 'Error subscribing email');
  }
};
