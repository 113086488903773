import React, { useState } from "react";
import { FaBars } from "react-icons/fa";
import MainMenu from "./MainMenu";
import MoreDropdown from "./MoreDropdown";
import LanguageDropdown from "./LanguageDropdown";
import MobileMenu from "./MobileMenu";
import { useTranslation } from "react-i18next";
import logo from "../../assets/cosmohole_logo.png"; // Adjust the path as necessary

const Navbar = () => {
  const { t } = useTranslation();
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => setMobileMenuOpen((prevState) => !prevState);

  const mainLinks = t("navbar.mainLinks", { returnObjects: true });
  const moreLinks = t("navbar.moreLinks", { returnObjects: true });
  const languageOptions = t("navbar.languageOptions", { returnObjects: true });

  return (
    <nav className="bg-gray-900 text-white shadow-lg">
      <div className="container mx-auto flex justify-between items-center py-4 px-6">
        {/* Logo Image */}
        <div className="flex items-center">
          <img src={logo} alt="Cosmohole Logo" style={{ height: "90px" }} />{" "}
          {/* Adjust height as needed */}
        </div>

        {/* Desktop Menu */}
        <div className="hidden md:flex items-center space-x-8">
          <MainMenu
            mainLinks={mainLinks}
            className="text-white font-semibold hover:text-blue-300 transition duration-200"
          />
          <MoreDropdown
            moreLinks={moreLinks}
            className="text-white font-semibold hover:text-blue-300 transition duration-200"
          />
          <LanguageDropdown
            languageOptions={languageOptions}
            className="text-white hover:text-blue-300 transition duration-200"
          />
        </div>

        {/* Mobile Menu Button */}
        <button
          className="md:hidden flex items-center text-white focus:outline-none hover:text-blue-400 transition duration-300"
          onClick={toggleMobileMenu}
        >
          <FaBars size={28} />
        </button>
      </div>

      {/* Mobile Menu */}
      {isMobileMenuOpen && (
        <div className="md:hidden fixed inset-0 bg-gray-800 bg-opacity-95 z-50 flex flex-col">
          <MobileMenu
            mainLinks={mainLinks}
            moreLinks={moreLinks}
            languageOptions={languageOptions}
            onClose={toggleMobileMenu}
          />
        </div>
      )}
    </nav>
  );
};

export default Navbar;
