import axios from 'axios';

// Backend API URL'si
const API_URL = 'http://localhost:5000/api/reviews';

// Tüm yorumları çekme
export const fetchReviews = async () => {
  try {
    const response = await axios.get(API_URL);
    return response.data;  // Yorumları döndür
  } catch (error) {
    throw new Error('Yorumlar çekilirken bir hata oluştu: ' + error.message);
  }
};

// Yeni bir yorum ekleme
export const addReview = async (newReview) => {
  try {
    const response = await axios.post(API_URL, newReview);
    return response.data;  // Yeni yorumu döndür
  } catch (error) {
    throw new Error('Yorum eklenirken bir hata oluştu: ' + error.message);
  }
};

// Bir yorumu güncelleme
export const updateReview = async (id, updatedReview) => {
  try {
    const response = await axios.put(`${API_URL}/${id}`, updatedReview);
    return response.data;  // Güncellenmiş yorumu döndür
  } catch (error) {
    throw new Error('Yorum güncellenirken bir hata oluştu: ' + error.message);
  }
};

// Bir yorumu silme
export const deleteReview = async (id) => {
  try {
    const response = await axios.delete(`${API_URL}/${id}`);
    return response.data;  // Silme mesajını döndür
  } catch (error) {
    throw new Error('Yorum silinirken bir hata oluştu: ' + error.message);
  }
};
