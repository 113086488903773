import React from 'react';
import { useTranslation } from 'react-i18next';
import CompanyInfo from './CompanyInfo';
import QuickLinks from './QuickLinks';
import StayUpdated from './StayUpdated';
import FollowUs from './FollowUs';

const Footer = () => {
  const { t } = useTranslation();

  return (
    <footer className="bg-gray-900 text-white py-16 mt-auto">
      <div className="container mx-auto px-6 lg:px-12">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-10">
          {/* Kurumsal Stil İçerikler */}
          <CompanyInfo />
          <QuickLinks />
          <StayUpdated />
          <FollowUs />
        </div>

        {/* Alt Bölüm - Telif Hakkı ve Diğer Bilgiler */}
        <div className="border-t border-gray-700 mt-10 pt-6">
          <p className="text-gray-400 text-sm text-center">
            {t('footer.copyright')}
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
