import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { FaAngleDown } from 'react-icons/fa';
import LanguageDropdown from './LanguageDropdown'; // Dil dropdown'unu dahil ediyoruz

const MobileMenu = ({ mainLinks, moreLinks, languageOptions, onClose }) => {
  const [isMoreOpen, setMoreOpen] = useState(false);
  const menuRef = useRef(null);

  const toggleMore = () => setMoreOpen(!isMoreOpen);

  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      onClose();
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div ref={menuRef} className="md:hidden bg-white border-t border-gray-300 py-3 px-4">
      <ul className="space-y-3">
        {mainLinks.map((link) => (
          <li key={link.name}>
            <Link
              to={link.path}
              className="block text-gray-700 hover:text-blue-500 transition duration-200 py-2 px-3"
              onClick={onClose}
            >
              {link.name}
            </Link>
          </li>
        ))}

        <li>
          <button
            className="flex items-center text-gray-700 hover:text-blue-500 transition duration-200 py-2 px-3"
            onClick={toggleMore}
          >
            More <FaAngleDown className="ml-1" />
          </button>
          {isMoreOpen && (
            <ul className="mt-1 space-y-1">
              {moreLinks.map((link) => (
                <li key={link.name} className="ml-4">
                  <Link
                    to={link.path}
                    className="block text-gray-700 hover:text-blue-500 transition duration-200 py-2 px-3"
                    onClick={onClose}
                  >
                    {link.name}
                  </Link>
                </li>
              ))}
            </ul>
          )}
        </li>

        {/* Dil Dropdown'unu buraya ekliyoruz */}
        <li className="mt-4">
          <LanguageDropdown languageOptions={languageOptions} />
        </li>
      </ul>
    </div>
  );
};

export default MobileMenu;
