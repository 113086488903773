import React from 'react';
import { useTranslation } from 'react-i18next';

const CompanyInfo = () => {
  const { t } = useTranslation();
  const { name, description, founded, email } = t('footer.companyInfo', { returnObjects: true });

  return (
    <div>
      <h4 className="text-xl font-bold mb-4">{name}</h4>
      <p className="text-gray-400 mb-2">{description}</p>
      <p className="text-gray-400 mb-2">{founded}</p>
      <p className="text-gray-400">Email: <a href={`mailto:${email}`} className="text-blue-400 hover:underline">{email}</a></p>
    </div>
  );
};

export default CompanyInfo;
