// src/components/PortfolioTestimonials.js
import React from 'react';
import { useTranslation } from 'react-i18next';

const testimonials = [
  {
    id: 1,
    nameKey: 'portfolio.testimonials.client1.name',
    feedbackKey: 'portfolio.testimonials.client1.feedback',
  },
  {
    id: 2,
    nameKey: 'portfolio.testimonials.client2.name',
    feedbackKey: 'portfolio.testimonials.client2.feedback',
  },
  {
    id: 3,
    nameKey: 'portfolio.testimonials.client3.name',
    feedbackKey: 'portfolio.testimonials.client3.feedback',
  },
];

const PortfolioTestimonials = () => {
  const { t } = useTranslation();

  return (
    <section className="py-20 bg-gray-100">
      <div className="container mx-auto px-6">
        <h2 className="text-4xl font-bold text-center mb-12">{t('portfolio.testimonials.title')}</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {testimonials.map(testimonial => (
            <div key={testimonial.id} className="bg-white shadow-lg rounded-lg p-6 transition-transform transform hover:scale-105">
              <p className="text-gray-700 italic text-lg">"{t(testimonial.feedbackKey)}"</p>
              <p className="mt-4 font-semibold text-gray-900">{t(testimonial.nameKey)}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default PortfolioTestimonials;
