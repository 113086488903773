import React from 'react';
import { useTranslation } from 'react-i18next';

const CallToAction = () => {
  const { t } = useTranslation();

  return (
    <section className="bg-gradient-to-r from-blue-900 to-blue-600 py-16 text-white text-center shadow-lg">
      <div className="container mx-auto px-6 lg:px-8">
        <h2 className="text-3xl md:text-4xl font-extrabold mb-6">{t('default.callToAction.title')}</h2>
        <p className="text-lg max-w-2xl mx-auto mb-8 px-4 md:px-0 leading-relaxed">
          {t('default.callToAction.description')}
        </p>
        <a 
          href="/contact" 
          className="inline-block bg-white text-blue-800 font-semibold px-8 py-4 rounded-full shadow-lg transition duration-300 transform hover:scale-105 hover:bg-gray-200"
        >
          {t('default.callToAction.buttonText')}
        </a>
      </div>
    </section>
  );
};

export default CallToAction;
