// src/components/Conclusion.js
import React from 'react';
import { useTranslation } from 'react-i18next';

const Conclusion = () => {
  const { t } = useTranslation();

  return (
    <section className="py-20 bg-gradient-to-r from-blue-500 to-blue-700 text-white text-center">
      <div className="container mx-auto px-6">
        <h2 className="text-5xl font-bold mb-8">{t('project1.conclusion.title')}</h2>
        <p className="text-xl mb-8 max-w-4xl mx-auto">{t('project1.conclusion.description')}</p>
      </div>
    </section>
  );
};

export default Conclusion;
