import React from "react";
import ServiceSection from "../components/Services/ServiceSection";
import Hero from "../components/Hero";
import CallToAction from "../components/CallToAction";
import { useTranslation } from 'react-i18next';

const Services = () => {
  const { t } = useTranslation();
  window.scrollTo(0, 0);


  return (
    <div className="bg-gray-100">
      {/* Hero Bileşeni */}
      <div className="animate-fadeInUp">
        <Hero
          titleKey="services.hero.title"
          descriptionKey="services.hero.description"
        />
      </div>

      {/* ServiceSection Bileşeni */}
      <div className="animate-fadeInUp delay-200">
        <ServiceSection />
      </div>

      {/* CallToAction Bileşeni */}
      <div className="animate-fadeInUp delay-400">
        <CallToAction 
          title={t('services.callToAction.title')} 
          description={t('services.callToAction.description')} 
          buttonText={t('services.callToAction.buttonText')} 
          buttonLink="/contact"
        />
      </div>
    </div>
  );
};

export default Services;
