import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';

const ContactForm = () => {
  const { t } = useTranslation();
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    message: '',
  });

  const [isSubmitted, setIsSubmitted] = useState(false);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); // Gönderim işlemi başladığında yükleniyor durumunu true yap
    setError(null); // Hata durumunu sıfırla

    try {
      // API URL'sini backend ile uyumlu hale getirin
      await axios.post('http://localhost:5000/api/contact', formData); // Form verilerini gönderiyoruz
      setIsSubmitted(true); // Form başarıyla gönderildi
      setFormData({
        name: '',
        email: '',
        subject: '',
        message: '',
      });
    } catch (err) {
      setError('Mesaj gönderilirken bir hata oluştu.'); // Hata mesajı
      console.error('Error:', err);
    } finally {
      setLoading(false); // Gönderim işlemi bittiğinde yükleniyor durumunu false yap
    }
  };

  return (
    <div className="w-full">
      {isSubmitted ? (
        <div className="text-center p-8">
          <h3 className="text-2xl font-semibold text-green-500">{t('contact.form.thankYouTitle')}</h3>
          <p className="text-gray-700 mt-4">{t('contact.form.thankYouMessage')}</p>
        </div>
      ) : (
        <form onSubmit={handleSubmit} className="space-y-6">
          {error && <p className="text-red-500">{error}</p>} {/* Hata mesajı */}
          <div className="flex flex-col">
            <label htmlFor="name" className="text-lg font-medium text-gray-700">{t('contact.form.fullNameLabel')}</label>
            <input
              type="text"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
              className="mt-2 p-3 border border-gray-300 rounded-lg focus:ring-blue-500 focus:border-blue-500"
              placeholder={t('contact.form.fullNamePlaceholder')}
            />
          </div>

          <div className="flex flex-col">
            <label htmlFor="email" className="text-lg font-medium text-gray-700">{t('contact.form.emailLabel')}</label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
              className="mt-2 p-3 border border-gray-300 rounded-lg focus:ring-blue-500 focus:border-blue-500"
              placeholder={t('contact.form.emailPlaceholder')}
            />
          </div>

          <div className="flex flex-col">
            <label htmlFor="subject" className="text-lg font-medium text-gray-700">{t('contact.form.subjectLabel')}</label>
            <input
              type="text"
              id="subject"
              name="subject"
              value={formData.subject}
              onChange={handleChange}
              required
              className="mt-2 p-3 border border-gray-300 rounded-lg focus:ring-blue-500 focus:border-blue-500"
              placeholder={t('contact.form.subjectPlaceholder')}
            />
          </div>

          <div className="flex flex-col">
            <label htmlFor="message" className="text-lg font-medium text-gray-700">{t('contact.form.messageLabel')}</label>
            <textarea
              id="message"
              name="message"
              value={formData.message}
              onChange={handleChange}
              required
              rows="6"
              className="mt-2 p-3 border border-gray-300 rounded-lg focus:ring-blue-500 focus:border-blue-500"
              placeholder={t('contact.form.messagePlaceholder')}
            />
          </div>

          <button
            type="submit"
            className="w-full bg-blue-500 text-white text-lg font-medium py-3 rounded-lg hover:bg-blue-600 transition-all duration-300"
            disabled={loading} // Butonu yükleniyor durumunda devre dışı bırak
          >
            {loading ? t('contact.form.loadingButtonText') : t('contact.form.sendButtonText')}
          </button>
        </form>
      )}
    </div>
  );
};

export default ContactForm;
