import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const QuickLinks = () => {
  const { t } = useTranslation();

  // 'footer.quickLinks.quickLinks' ile doğru diziyi alıyoruz
  const links = t('footer.quickLinks.quickLinks', { returnObjects: true });

  // Konsolda veriyi kontrol edelim
  console.log('Links Data:', links);

  // Eğer links dizi değilse hata mesajı döndürelim
  if (!Array.isArray(links)) {
    console.error("QuickLinks is not an array. Check your translation files.");
    return <p>Error: QuickLinks data is not available</p>;
  }

  return (
    <div>
      <h4 className="text-xl font-bold mb-4">{t('footer.quickLinks.quickLinksTitle')}</h4>
      <ul className="space-y-2">
        {links.map((link, index) => (
          <li key={index}>
            <Link to={link.path} className="text-gray-400 hover:text-blue-400 transition">
              {link.label}
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default QuickLinks;
