import React from "react";
import { useTranslation } from "react-i18next";

const JobOpenings = () => {
  const { t } = useTranslation();

  return (
    <section className="container mx-auto py-20 px-4">
      <h2 className="text-5xl font-bold text-gray-800 mb-12 text-center">
        {t("careers.jobOpenings.title")}
      </h2>
      <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-12">
        {/* Job Card 1: Data Scientist */}
        <div className="bg-white rounded-lg shadow-lg p-6 hover:shadow-2xl transition duration-300">
          <h3 className="text-2xl font-semibold mb-2">
            {t("careers.jobOpenings.dataScientist.title")}
          </h3>
          <p className="text-gray-700 mb-4">
            {t("careers.jobOpenings.dataScientist.description")}
          </p>
          <a
            href="/careers/data-scientist"
            className="text-blue-600 font-semibold hover:underline"
          >
            {t("careers.jobOpenings.viewDetails")}
          </a>
        </div>

        {/* Job Card 2: Software Engineer */}
        <div className="bg-white rounded-lg shadow-lg p-6 hover:shadow-2xl transition duration-300">
          <h3 className="text-2xl font-semibold mb-2">
            {t("careers.jobOpenings.softwareEngineer.title")}
          </h3>
          <p className="text-gray-700 mb-4">
            {t("careers.jobOpenings.softwareEngineer.description")}
          </p>
          <a
            href="/careers/software-engineer"
            className="text-blue-600 font-semibold hover:underline"
          >
            {t("careers.jobOpenings.viewDetails")}
          </a>
        </div>

        {/* Job Card 3: Project Manager */}
        <div className="bg-white rounded-lg shadow-lg p-6 hover:shadow-2xl transition duration-300">
          <h3 className="text-2xl font-semibold mb-2">
            {t("careers.jobOpenings.projectManager.title")}
          </h3>
          <p className="text-gray-700 mb-4">
            {t("careers.jobOpenings.projectManager.description")}
          </p>
          <a
            href="/careers/project-manager"
            className="text-blue-600 font-semibold hover:underline"
          >
            {t("careers.jobOpenings.viewDetails")}
          </a>
        </div>
      </div>
    </section>
  );
};

export default JobOpenings;
