import React from "react";
import CompanyOverview from "../components/About/CompanyOverview";
import CallToAction from "../components/CallToAction";
import Hero from "../components/Hero";
import { useTranslation } from "react-i18next";

const About = () => {
  const { t } = useTranslation();
  window.scrollTo(0, 0);

  return (
    <div className="bg-gray-100 min-h-screen flex flex-col items-center">
      {/* Kahraman Bölümü */}
      <div className="w-full animate-fadeInUp">
        <Hero
          titleKey="about.hero.title"
          descriptionKey="about.hero.description"
        />
      </div>

      {/* Şirket Tanıtım Bölümü */}
      <div className="w-full animate-fadeInUp delay-200 p-4 sm:p-6 md:p-8">
        <CompanyOverview />
      </div>

      {/* Çağrı Yap Bölümü */}
      <div className="w-full animate-fadeInUp delay-400">
        <CallToAction
          title={t("about.callToAction.title")}
          description={t("about.callToAction.description")}
          buttonText={t("about.callToAction.buttonText")}
          buttonLink="/contact"
        />
      </div>
    </div>
  );
};

export default About;
