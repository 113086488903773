import React from "react";

const ReviewItem = ({ review }) => {
  return (
    <div className="bg-white rounded-lg shadow-lg w-full p-6 mb-4 hover:shadow-2xl transition-shadow duration-300 border border-gray-200 relative">
      <h2 className="text-2xl font-bold text-gray-800 mb-2">
        {review.customerName}
      </h2>
      <p className="text-gray-700 mb-4">{review.review}</p>
      <div className="flex items-center">
        <span className="text-gray-500 font-medium">Puan:</span>
        <span className="text-yellow-500 font-bold ml-2">{'⭐'.repeat(review.rating)}</span>
      </div>
      <p className="text-gray-400 text-sm mt-1">
        {new Date(review.date).toLocaleDateString()}
      </p>
    </div>
  );
};

export default ReviewItem;
