import React from 'react';
import { Link } from 'react-router-dom';

const MainMenu = ({ mainLinks }) => {
  return (
    <ul className="hidden md:flex space-x-3"> {/* Buradaki space-x-6'yı space-x-4 ile değiştirdik */}
      {mainLinks.map((link) => (
        <li key={link.name} className="relative group">
          <Link 
            to={link.path} 
            className="text-gray-300 hover:text-blue-500 transition-all md:text-lg sm:text-sm" // Tablet için daha küçük yazı boyutu
          >
            {link.name}
          </Link>
          <span className="absolute bottom-0 left-0 w-0 h-0.5 bg-blue-500 transition-all duration-300 group-hover:w-full"></span>
        </li>
      ))}
    </ul>
  );
};

export default MainMenu;
