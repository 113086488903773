// src/components/PortfolioGallery.js
import React from 'react';
import { useTranslation } from 'react-i18next';

const PortfolioGallery = () => {
  const { t } = useTranslation();

  // Örnek proje verileri
  const projects = [
    {
      id: 1,
      title: t('portfolio.gallery.project1.title'),
      description: t('portfolio.gallery.project1.description'),
      imageUrl: '/images/project1.jpg',
      detailsUrl: '/portfolio/project1', // Proje detay sayfası bağlantısı
    },
    {
      id: 2,
      title: t('portfolio.gallery.project2.title'),
      description: t('portfolio.gallery.project2.description'),
      imageUrl: '/images/project2.jpg',
      detailsUrl: '/portfolio/project2',
    },
    {
      id: 3,
      title: t('portfolio.gallery.project3.title'),
      description: t('portfolio.gallery.project3.description'),
      imageUrl: '/images/project3.jpg',
      detailsUrl: '/portfolio/project3',
    },
  ];

  return (
    <section className="py-20 bg-white">
      <div className="container mx-auto px-6 lg:px-8">
        <h2 className="text-4xl font-bold text-center mb-12">{t('portfolio.gallery.title')}</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {projects.map((project) => (
            <div key={project.id} className="bg-gray-100 rounded-lg overflow-hidden shadow-lg transition-transform transform hover:scale-105">
              <img src={project.imageUrl} alt={project.title} className="w-full h-48 object-cover" />
              <div className="p-4">
                <h3 className="text-xl font-semibold mb-2">{project.title}</h3>
                <p className="text-gray-600 mb-4">{project.description}</p>
                <a href={project.detailsUrl} className="text-blue-600 font-semibold hover:underline">
                  {t('portfolio.gallery.viewDetails')}
                </a>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default PortfolioGallery;
